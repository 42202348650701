import React from 'react'
import {useState, useEffect} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom'
import { LoginContext } from './Context/LoginContext';
import PrivateRoutes from './utils/PrivateRoutes';

import Layout from './Layout/Layout';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import TyreData from './TyreData/TyreData';
import Charts from  './Charts/Charts';
// import TyreStatus from './TyreStatus/TyreStatus';
import WidthDropdownFunc from './Functions/ManualDropdownsFront/WidthDropdownFunc';
import AllDropdowns  from './Functions/ManualDropdownsFront/ManualDropdowns';
import WidthDropdownFuncRear from './Functions/ManualDropdownsRear/WidthDropdownFuncRear';
import AllDropdownsRear from './Functions/ManualDropdownsRear/ManualDropdownsRear';
import CompanyLogo from './Components/Image';

function App() {
  const url = 'https://dataengine.paymonthlytyres.com/api/'
  // const url = "https://data.paymonthlytyres.com/api/"
  // const url = 'http://localhost:64836/api/'
  const baseUrl = `${url}Vehicle?registration=`;
  const navigate = useNavigate();
  
  const [message, setMessage] = useState('');
  const [invalidPlate, setInvalidPlate] = useState(false);
  const [noVehicleData, setNoVehicleData] = useState(false);
  // Populating the dropdown menu 
  const [tyreData, setTyreData] = useState([]);
  const [unpackedTyreData, setUnpackedTyreData] = useState([])
  // Card filling information
  const [model, setModel] = useState("");
  const [registration, setRegistration] = useState("");
  const [fleetEdi, setFleetEdi] = useState("");
  // Best size recommendation
  const [bestSizeFrontWidth, setBestSizeFrontWidth] = useState("");
  const [bestSizeFrontRatio, setBestSizeFrontRatio] = useState("");
  const [bestSizeFrontRim, setBestSizeFrontRim] = useState("");
  const [bestSpeedFront, setBestSpeedFront] = useState("");
  
  const [bestSizeRearWidth, setBestSizeRearWidth] = useState("");
  const [bestSizeRearRatio, setBestSizeRearRatio] = useState("");
  const [bestSizeRearRim, setBestSizeRearRim] = useState(""); 
  const [bestSpeedRear, setBestSpeedRear] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isDifferentTyres, setIsDifferentTyres] = useState(false);
  // Authentication variables 
  // const loginBaseUrl = "http://localhost:64836/api/Vehicle/GetTyreComparisonDataLogin?userName=";
  const loginBaseUrl = `${url}Vehicle/GetTyreComparisonDataLogin?userName=`;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState(""); 
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(window.localStorage.getItem("isLoggedIn"));
  // API KEY 
  const [apiKey, setApiKey] = useState(window.localStorage.getItem("API_KEY"));

  const [isTrue, setIsTrue] = useState(false);
  const [isTrueSecond, setIsTrueSecond] = useState(false);

  const [presetVal, setPresetVal] = useState("");
  const [presetValTwo, setPresetValTwo] = useState("");
  // Dropdown menu values of each line 

  const [isFrontTyre, setIsFrontTyre] = useState(true);

  // Price value variables for Charts table
  // const [priceValueOne, setPriceValueOne] = useState(0);
  // const [priceValueTwo, setPriceValueTwo] = useState(0);
  // const [priceValueThree, setPriceValueThree] = useState(0);
  // const [priceValueFour, setPriceValueFour] = useState(0);

  const [averageTyrePrice, setAverageTyrePrice] = useState(100); 

  // const [priceValueOneSecond, setPriceValueOneSecond] = useState(0);
  // const [priceValueTwoSecond, setPriceValueTwoSecond] = useState(0);
  // const [priceValueThreeSecond, setPriceValueThreeSecond] = useState(0);
  // const [priceValueFourSecond, setPriceValueFourSecond] = useState(0);

  const [averageTyrePriceSecond, setAverageTyrePriceSecond] = useState(100); 
  
  const [mileageInput, setMileageInput] = useState(8000);
  const [storedMileage, setStoredMileage] = useState(mileageInput);

  const [tyresAreDifferent, setTyresAreDifferent] = useState(false);
  // Tyre wear rate
  const [tyreWearRateAll, setTyreWearRateAll] = useState({});
  // const [tyreWearRateRear, setTyreWearRateRear] = useState({});

  const {RatioDropdownFunc, RimDropdownFunc, SpeedDropdownFunc} = AllDropdowns();
  const {getWidthValues, widthHTMLElement} = WidthDropdownFunc();
  const {widthVal, setWidthVal, dropdownWidthValue, setDropdownWidthValue, ratioHTMLElement, getRatioValues} = RatioDropdownFunc();
  const {ratioVal, setRatioVal, dropdownRatioValue, setDropdownRatioValue, rimHTMLElement, getRimValues} = RimDropdownFunc();
  const {speedHTMLElement, rimVal, setRimVal, speedVal, setSpeedVal, dropdownRimValue, setDropdownRimValue, dropdownSpeedValue, setDropdownSpeedValue, getSpeedValues, controlledSpeedVal} = SpeedDropdownFunc();
  
  // Rear tyres manual dropdown 
  const {RatioDropdownFuncSecond, RimDropdownFuncSecond, SpeedDropdownFuncSecond} = AllDropdownsRear();
  const {getWidthValuesTwo, widthHTMLElementSecond} = WidthDropdownFuncRear();
  const {widthValSecond, setWidthValSecond, dropdownWidthValueSecond, setDropdownWidthValueSecond, ratioHTMLElementSecond, getRatioValuesSecond} = RatioDropdownFuncSecond();
  const {ratioValSecond, setRatioValSecond, dropdownRatioValueSecond, setDropdownRatioValueSecond, rimHTMLElementSecond, getRimValuesSecond} = RimDropdownFuncSecond();
  const {speedHTMLElementSecond, rimValSecond, setRimValSecond, speedValSecond, setSpeedValSecond, dropdownRimValueSecond, setDropdownRimValueSecond, dropdownSpeedValueSecond, setDropdownSpeedValueSecond, getSpeedValuesSecond, controlledSpeedValSecond} = SpeedDropdownFuncSecond();
  
  // const chartsBaseUrl = `${url}Vehicle/GetTyreComparisonData?fleetnetEDI=`;
  const chartsBaseUrl = `${url}Vehicle/GetTyreComparisonDataBoth?fleetnetEDI=`;

// const chartsBaseUrl = "http://localhost:64836/api/Vehicle/GetTyreComparisonData?fleetnetEDI=";
  const [tableRowsArray, setTableRowsArray] = useState([]);
  const [createTableRows, setCreateTableRows] = useState([]);

  const [tableRowsArrayTwo, setTableRowsArrayTwo] = useState([]);
  const [createTableRowsTwo, setCreateTableRowsTwo] = useState([]);
  // Authentication function
  const [loggedInUser, setLoggedInUser] = useState(window.localStorage.getItem("userName"));
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const [tyreComparisonRequestsID, setTyreComparisonRequestsID] = useState("");

// Functions 
const newQueryRequest = () => {
  clearData();
  navigate("/dashboard-page");
} 
function handleDefaultSelectOption(item){
  if(item === "manualInput"){
    getWidthValues();
    
    setIsTrue(true);
  }else{
    setIsTrue(false);
    if(item !== "Tyre Size"){
      setDropdownWidthValue("");
      setDropdownRatioValue("");
      setDropdownRimValue("");
      setDropdownSpeedValue("");

      let valueArray = [item];
      let splitValueArray = valueArray.join(",").split(",");

      setDropdownWidthValue(splitValueArray[0]);
      setDropdownRatioValue(splitValueArray[1]);
      setDropdownRimValue(splitValueArray[2]);
      setDropdownSpeedValue(splitValueArray[3]);
        
    }
  }
}

function handleSelectOption(e){
  setPresetVal(e.target.value)
  if(e.target.value === "manualInput"){
    getWidthValues();
    
    setIsTrue(true);
  }else{
    setIsTrue(false);
    if(e.target.value !== "Tyre Size"){
      setDropdownWidthValue("");
      setDropdownRatioValue("");
      setDropdownRimValue("");
      setDropdownSpeedValue("");

      let valueArray = [e.target.value];
      let splitValueArray = valueArray.join(",").split(",");

      setDropdownWidthValue(splitValueArray[0]);
      setDropdownRatioValue(splitValueArray[1]);
      setDropdownRimValue(splitValueArray[2]);
      setDropdownSpeedValue(splitValueArray[3]);
        
    }
  }
}

function handleDefaultSelectOptionTwo(item){
  if(item === "manualInputTwo"){
    getWidthValuesTwo();
    
    setIsTrueSecond(true);
  }else{
    setIsTrueSecond(false);
    if(item !== "Tyre Size"){
      setDropdownWidthValueSecond("");
      setDropdownRatioValueSecond("");
      setDropdownRimValueSecond("");
      setDropdownSpeedValueSecond("");

      let valueArray = [item];
      let splitValueArray = valueArray.join(",").split(",");

      setDropdownWidthValueSecond(splitValueArray[0]);
      setDropdownRatioValueSecond(splitValueArray[1]);
      setDropdownRimValueSecond(splitValueArray[2]);
      setDropdownSpeedValueSecond(splitValueArray[3]);
        
    }
  }
}
function handleSelectOptionTwo(e){
  setPresetValTwo(e.target.value)
  if(e.target.value === "manualInputTwo"){
    getWidthValuesTwo();
    
    setIsTrueSecond(true);
  }else{
    setIsTrueSecond(false);
    if(e.target.value !== "Tyre Size"){
      setDropdownWidthValueSecond("");
      setDropdownRatioValueSecond("");
      setDropdownRimValueSecond("");
      setDropdownSpeedValueSecond("");

      let valueArray = [e.target.value];
      let splitValueArray = valueArray.join(",").split(",");

      setDropdownWidthValueSecond(splitValueArray[0]);
      setDropdownRatioValueSecond(splitValueArray[1]);
      setDropdownRimValueSecond(splitValueArray[2]);
      setDropdownSpeedValueSecond(splitValueArray[3]);
        
    }
  }
}
const clearData = () => { 
    setTyreData([]);
    setUnpackedTyreData([]);
    setModel("");
    setRegistration("");
    setBestSizeFrontWidth("");
    setBestSizeFrontRatio("");
    setBestSizeFrontRim("");
    setBestSpeedFront("")
    setBestSizeRearWidth("");
    setBestSizeRearRatio("");
    setBestSizeRearRim("");
    setBestSpeedRear("");

    //-------
    setCreateTableRows([]);
    setCreateTableRowsTwo([]);
    setDropdownWidthValue("");
    setDropdownRatioValue("");
    setDropdownRimValue("");
    setDropdownSpeedValue("");
    setDropdownWidthValueSecond("");
    setDropdownRatioValueSecond("");
    setDropdownRimValueSecond("");
    setDropdownSpeedValueSecond("");
    setFleetEdi("");
    setModel("");
    setRegistration("");
    setIsFrontTyre(true);
    setTableRowsArray([]);
    setTableRowsArrayTwo([]);

    setWidthVal("");
    setWidthValSecond("")
    setRatioVal("");
    setRimVal("");
    setSpeedVal("");
    setRatioValSecond("");
    setRimValSecond("");
    setSpeedValSecond("");
    setPresetVal("");
    setPresetValTwo("");

    setIsTrue(false);
    setIsTrueSecond(false);
    setMileageInput(8000);
    setTyresAreDifferent(false);
    setChartsArray([]);
    setTyreComparisonRequestsID("");
};
const handleLogin = (e) => { 

    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };
    fetch(`${loginBaseUrl}${username}&password=${password}`, requestOptions)
      .then(response => response.json())
      .then(result => {
      
        
        if(result["IsLoggedIn"]){
          window.localStorage.setItem("isLoggedIn", true);
          window.localStorage.setItem("API_KEY", result["ApiKey"]);
          window.localStorage.setItem("userName", result["UserName"]);
          
        
          setUserIsLoggedIn(window.localStorage.getItem("isLoggedIn"));
          setApiKey(result["ApiKey"]);
          
          
          navigate("/dashboard-page");
          window.location.reload(false);
          
        }else{
          window.localStorage.removeItem("isLoggedIn");
          setIsAlertVisible(true);

          setTimeout(() => {
            setIsAlertVisible(false);
          }, 3000);

        }
      })
      .catch(error => console.log('error', error));
}

// useEffects 
// IMPORTANT DO DISCUSS WITH RUSSELL
useEffect(() => {
  if (dropdownRimValue) {
    const apiKey = window.localStorage.getItem("API_KEY");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${apiKey}`);
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    const firstUrl = `${url}Vehicle/GetAverageAnnualMileage?fleetnetEDI=${fleetEdi}&tyreRim=${dropdownRimValue}&tyreIsFront=true`;
  
    const secondUrl = tyresAreDifferent
      ? `${url}Vehicle/GetAverageAnnualMileage?fleetnetEDI=${fleetEdi}&tyreRim=${dropdownRimValueSecond}&tyreIsFront=false`
      : `${url}Vehicle/GetAverageAnnualMileage?fleetnetEDI=${fleetEdi}&tyreRim=${dropdownRimValue}&tyreIsFront=false`;
  
    const fetchAverageAnnualMileage = (url, tyreType, targetProperty) => {
      fetch(`${url}&tyreType=${tyreType}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setTyreWearRateAll(prevState => ({ ...prevState, [targetProperty]: Number(result) }))
        })
        .catch(error => console.log('error', error));
    };
  
    fetchAverageAnnualMileage(`${firstUrl}`, 't', 'frontTotal');
    fetchAverageAnnualMileage(`${secondUrl}`, 't', 'rearTotal');
    fetchAverageAnnualMileage(`${firstUrl}`, 'b', 'frontBudget');
    fetchAverageAnnualMileage(`${secondUrl}`, 'b', 'rearBudget');
    fetchAverageAnnualMileage(`${firstUrl}`, 'p', 'frontPremium');
    fetchAverageAnnualMileage(`${secondUrl}`, 'p', 'rearPremium');
  }
}, [fleetEdi, dropdownRimValue, dropdownRimValueSecond]);



const [chartsArray, setChartsArray] = useState([]);
const [suggestedFitments, setSuggestedFitments] = useState({})

useEffect(() => {
  if(tableRowsArray[0] !== "No data"){
    // tableRowsArray.sort((a, b) => (Number(b[2]) + Number(b[3])) - (Number(a[2]) + Number(a[3]))) 
    tableRowsArray.sort((a, b) => {
      const sumA = (Number(a[2]) || Number(a[3])) + (Number(a[3]) || Number(a[2]));
      const sumB = (Number(b[2]) || Number(b[3])) + (Number(b[3]) || Number(b[2]));
      return sumB - sumA;
    });
    
    let averageTyrePrices = 0;
    let counter = 0
    tableRowsArray.forEach(line => {
      let average = Number(line[4]);
      let secondAverage = Number(line[5]);
      if(average === 0){
        average = secondAverage;
      }
      if(secondAverage === 0){
        secondAverage = average;
      }
      let sum = average + secondAverage;
      let averageSum = sum / 2
      averageTyrePrices += averageSum;
      counter++; 
    })
    
    let totalAverage = Math.floor((averageTyrePrices / (counter - 1)));
    
    let suggestedTyreChecker = false;
    let spinner = 3;

    const trArray = tableRowsArray.map((ele, i) => {
      const [tyreComparisonID, tyreBrand, wearRateFront, wearRateRear, tyrePriceFront, tyrePriceRear, numFitmentsFront, numFitmentsRear ] = ele;
      let wearRateFrontNew = wearRateFront;
      let wearRateRearNew = wearRateRear;
      let tyrePriceFrontNew = tyrePriceFront;
      let tyrePriceRearNew = tyrePriceRear;


      if(tyreBrand === 'Budget' && i < 3){
        spinner = 4;
      }
      
      // let averageTyrePriceConverter = averageTyrePrice;
      setTyreComparisonRequestsID(tyreComparisonID);
      let numFitments = Number(numFitmentsFront) + Number(numFitmentsRear)
      if(wearRateFront.length === 0 ){
        if(wearRateRear.length !== 0){
          wearRateFrontNew = wearRateRear;
        }else {
          wearRateFrontNew = '1';
        }
      }
      if(wearRateRear.length === 0 ){
        if(wearRateFront.length !== 0){
          wearRateRearNew = wearRateFront;
        }else {
          wearRateRearNew = '1';
        }
      }
      if(tyrePriceFront.length === 0 ){
        if(tyrePriceRear !== 0){
          tyrePriceFrontNew = tyrePriceRear;
        }else{
          tyrePriceFrontNew = '1';
        }
      }
      if(tyrePriceRear.length === 0 ){
        if(tyrePriceFront.length){
          tyrePriceRearNew = tyrePriceFront
        }else {
          tyrePriceRearNew = '1';
        }
      }
      
      
      const allFourTyresPrice = ((Number(tyrePriceFrontNew) * 2) + (Number(tyrePriceRearNew) * 2)) / 4;
      
      let defaultPriceValue = 0;
      
      let wearRate = Math.ceil((Number(wearRateFrontNew) + Number(wearRateRearNew) ) / 2);
      let tyresWearRate = (tyreWearRateAll.frontTotal + tyreWearRateAll.rearTotal) / 2;
      // console.log(`${tyreBrand} => ${wearRateFrontNew} + ${wearRateRearNew} = ${Number(wearRateFrontNew) + Number(wearRateRearNew)}`)
      let averageTyreLifeInYears = tyresWearRate / storedMileage;
      
      
      
      const averageProfit = totalAverage * (1 + (wearRate / 100))
      defaultPriceValue = averageProfit - allFourTyresPrice; 
      
      let condotional = (numFitments >= 25) ? <td style={{color: "#008000"}}>High</td> : numFitments <= 24 && numFitments >= 10 ? <td style={{color: "#FFA500"}}>Medium</td> : <td style={{color: "#ff0000"}}>Low</td>
      // actualSavings = ((allFourTyresPrice * percentageCalcBuild) - totalAverage);
      if(suggestedTyreChecker === true){
        setSuggestedFitments(oldState => ({...oldState, tyreBrand: tyreBrand, wearRate: wearRate, totalSavings: defaultPriceValue }))
        suggestedTyreChecker = false;
      }
      

      for(let j = 0; j < spinner; j++){
        if(i < spinner && wearRate > 0 && tyreBrand !=='Budget' && tyreBrand !== 'Average'){
          
          
          if(!chartsArray.includes(tyreBrand)){
            setChartsArray(oldState => [...oldState, tyreBrand, wearRate]);
          }
          let annualSavings =  (defaultPriceValue / averageTyreLifeInYears);
          if(i === 0){
            setSuggestedFitments(oldState => ({...oldState, tyreBrand: tyreBrand, wearRate: wearRate, totalSavings: defaultPriceValue }))
          }
          
            return (
              
              <tr key={i}>
                    {/* <td  style={{backgroundColor: "#0000FF", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td> */}
                    <td>{<CompanyLogo imageSource={tyreBrand} logo={false}/>}</td>
                    {window.localStorage.getItem("userName") === "stoyan" || window.localStorage.getItem("userName") === "hmhprice" 
                    ?
                    <>
                    <td >{allFourTyresPrice}</td>
                    <td >{totalAverage}</td>
                    </>
                    : 
                    <></>
          }
                    <td >{wearRate}%</td>
                    {/* <td >£{monthlySavings.toFixed(2)}</td> */}
                    <td >£{(defaultPriceValue * 4).toFixed(2)}</td>
                    <td >£{(annualSavings * 4).toFixed(2)}</td>
                    {/* <td >{actualTimeToSavings}</td> */}
                    {condotional}
                </tr>
               
                )
            
  
          }
      }

      // if(i === 0 && wearRate > 0 && tyreBrand !=='Budget' && tyreBrand !== 'Average'){
          
      //   if(!chartsArray.includes(tyreBrand)){
      //     setChartsArray(oldState => [...oldState, tyreBrand, wearRate]);
      //   }
      //   let annualSavings =  (defaultPriceValue / averageTyreLifeInYears);
      //   setSuggestedFitments(oldState => ({...oldState, tyreBrand: tyreBrand, wearRate: wearRate, totalSavings: defaultPriceValue }))
        
      //     return (
            
      //       <tr key={i}>
      //             {/* <td  style={{backgroundColor: "#0000FF", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td> */}
      //             <td>{<CompanyLogo imageSource={tyreBrand} logo={false}/>}</td>
      //             {window.localStorage.getItem("userName") === "stoyan" || window.localStorage.getItem("userName") === "hmhprice" 
      //             ?
      //             <>
      //             <td >{allFourTyresPrice}</td>
      //             <td >{totalAverage}</td>
      //             </>
      //             : 
      //             <></>
      //   }
      //             <td >{wearRate}%</td>
      //             {/* <td >£{monthlySavings.toFixed(2)}</td> */}
      //             <td >£{(defaultPriceValue * 4).toFixed(2)}</td>
      //             <td >£{(annualSavings * 4).toFixed(2)}</td>
      //             {/* <td >{actualTimeToSavings}</td> */}
      //             {condotional}
      //         </tr>
             
      //         )
          

      //   }else if (i === 1 && wearRate > 0 && tyreBrand !=='Budget' && tyreBrand !== 'Average'){
         
      //     if(!chartsArray.includes(tyreBrand)){
      //       setChartsArray(oldState => [...oldState, tyreBrand, wearRate]);
      //     }

      //   let annualSavings = (defaultPriceValue / averageTyreLifeInYears); 

      //   return (
         
      //       <tr key={i}>
      //           {/* <td  style={{backgroundColor: "#0096FF", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td> */}
      //           <td>{<CompanyLogo imageSource={tyreBrand} logo={false}/>}</td>
      //           {window.localStorage.getItem("userName") === "stoyan" || window.localStorage.getItem("userName") === "hmhprice" 
      //             ?
      //             <>
      //             <td >{allFourTyresPrice}</td>
      //             <td >{totalAverage}</td>
      //             </>
      //             : 
      //             <></>
      //   }
      //           <td >{wearRate}%</td>
      //           {/* <td >£{(annualSavings / 12).toFixed(2)}</td> */}
      //           <td >£{(defaultPriceValue * 4).toFixed(2)}</td>
      //           <td >£{(annualSavings * 4).toFixed(2)}</td>
      //           {/* <td >{actualTimeToSavings}</td> */}
      //           {condotional}

      //       </tr>
         
      //     )
          
      //   }else if (i === 2 && wearRate > 0 && tyreBrand !=='Budget' && tyreBrand !== 'Average'){
      //     if(!chartsArray.includes(tyreBrand)){
      //       setChartsArray(oldState => [...oldState, tyreBrand, wearRate]);
      //     }
         
      //     let annualSavings = (defaultPriceValue / averageTyreLifeInYears);

      //     return (
           
      //       <tr key={i}>
      //           {/* <td  style={{backgroundColor: "#87CEEB", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td> */}
      //           <td>{<CompanyLogo imageSource={tyreBrand} logo={false}/>}</td>
      //           {window.localStorage.getItem("userName") === "stoyan" || window.localStorage.getItem("userName") === "hmhprice" 
      //             ?
      //             <>
      //             <td >{allFourTyresPrice}</td>
      //             <td >{totalAverage}</td>
      //             </>
      //             : 
      //             <></>
      //   }
      //           {/* <td ><input type="number" style={{width: "50%", minWidth: "70px"}} onChange={(e) => settyrePriceInput(e.target.value)} value={tyrePriceInput === 0 ? tyrePriceInput : tyrePriceInput}></input></td> */}
      //           <td >{wearRate}%</td>
      //           {/* <td >£{(annualSavings / 12).toFixed(2)}</td> */}
      //           <td >£{(defaultPriceValue * 4).toFixed(2)}</td>
      //           <td >£{(annualSavings * 4).toFixed(2)}</td>
      //           {/* <td >{actualTimeToSavings}</td> */}
      //           {condotional}
      //       </tr>
          
      //     )
          
      //   }
        if(tyreBrand ==='Average' && wearRateFrontNew.length > 0){
          if(i === 0){
            setSuggestedFitments(oldState => ({...oldState, tyreBrand: tyreBrand, wearRate: wearRate, totalSavings: defaultPriceValue }))
          }
          return (
            <tr key={i}>
          {/* <td  style={{backgroundColor: "#fff", color: "#000000", fontWeight: "bold"}}>{tyreBrand}</td> */}
            <td><b>{tyreBrand}</b></td>
          {/* <td ><input type="number" style={{width: "50%", minWidth: "70px"}} onChange={(e) => settyrePriceInput(e.target.value)} value={tyrePriceInput === 0 ? tyrePriceInput : tyrePriceInput}></input></td> */}
          {window.localStorage.getItem("userName") === "stoyan" || window.localStorage.getItem("userName") === "hmhprice" 
                  ?
                  <>
                  <td >{allFourTyresPrice}</td>
                  <td >{totalAverage}</td>
                  </>
                  : 
                  <></>
        }
          <td >0%</td>
          {/* <td >£{(annualSavings / 12).toFixed(2)}</td> */}
          <td >£0</td>
          <td >£0</td>
          {/* <td >{actualTimeToSavings}</td> */}
          <td style={{color: "#008000"}}>High</td>
      </tr>
      
          )
          
        }
        
        else if( tyreBrand ==='Budget' && wearRateFrontNew.length > 0) {
          return;
        //  if((wearRateFrontNew.length > 0) ){
        //   // setChartsArray(oldState => [...oldState, tyreBrand]);
        //   // setChartsArray(oldState => [...oldState, wearRate]);
        //   if(!chartsArray.includes(tyreBrand)){
        //     setChartsArray(oldState => [...oldState, tyreBrand, wearRate]);
        //   }
        //   if(i === 0){
        //     suggestedTyreChecker = true;
        //     // setSuggestedFitments(oldState => ({...oldState, tyreBrand: tyreBrand, wearRate: wearRate, totalSavings: defaultPriceValue }))
        //   }
         
        //   let annualSavings = ( defaultPriceValue / averageTyreLifeInYears);
    
        //   return (
        //     <>
        //     <tr>
        //     {/* <td  style={{backgroundColor: "#808080", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td> */}
        //     <td><b>{tyreBrand}</b></td>
        //     {window.localStorage.getItem("userName") === "stoyan" || window.localStorage.getItem("userName") === "hmhprice" 
        //           ?
        //           <>
        //           <td >{allFourTyresPrice}</td>
        //           <td >{totalAverage}</td>
        //           </>
        //           : 
        //           <></>
        // }

        //     {/* <td ><input type="number" style={{width: "50%", minWidth: "70px"}} onChange={(e) => settyrePriceInput(e.target.value)} value={tyrePriceInput === 0 ? tyrePriceInput : tyrePriceInput}></input></td> */}
        //     <td >{wearRate}%</td>
        //     {/* <td >£{(annualSavings / 12).toFixed(2)}</td> */}
        //     <td >£{(defaultPriceValue * 4).toFixed(2)}</td>
        //     <td >£{(annualSavings * 4).toFixed(2)}</td>
        //     {/* <td >{actualTimeToSavings}</td> */}
        //     {condotional}
        // </tr>
        //        </>
        //      )
        //  }
        }
    })

    setCreateTableRows(trArray);
  }
 
},[tableRowsArray, averageTyrePrice, storedMileage]);




// useEffect(() => {

// if(tableRowsArrayTwo[0] !== "No data"){
//   const trArray = tableRowsArrayTwo.map((ele, i) => {
//     const [tyreBrand, wearRate, costSaving, tyrePrice, numFitments ] = ele;
    
//     let tyrePriceInput = Number(tyrePrice);
//     let wearRateNum = Number(wearRate);

//     let percentageCalcBuild = 0;
//     let percentageCalcNum = 0;
    
//     // let defaultPercentage;
//     let defaultPriceValue = 0;
//     let averageTyreLifeInYears = tyreWearRateRear / storedMileage;

//     if(wearRateNum >= 0){
//       percentageCalcBuild = Math.abs((wearRateNum + 100) / 100);
//       percentageCalcNum = percentageCalcBuild;

//       // defaultPercentage = `1.${wearRate}`
//       defaultPriceValue = ((averageTyrePriceSecond * percentageCalcBuild) - tyrePriceInput)

//     }else {
//       percentageCalcBuild = Math.abs((wearRateNum + 100) / 100);
//       percentageCalcNum = percentageCalcBuild;

     
//       defaultPriceValue = ((averageTyrePriceSecond * percentageCalcBuild) - tyrePriceInput)
//     }
    
    
//     let actualSavings = 0;
   
//     let condotional = (Number(numFitments) >= 25) ? <td style={{color: "#008000"}}>High</td> : (Number(numFitments) <= 24 && Number(numFitments) >= 10) ? <td style={{color: "#FFA500"}}>Medium</td> : <td style={{color: "#ff0000"}}>Low</td>
//     if(i === 0 && wearRate.length > 0){

//       actualSavings = ((averageTyrePriceSecond * percentageCalcNum) - priceValueOneSecond);
//       let annualSavings = priceValueOneSecond === 0 ? (defaultPriceValue / averageTyreLifeInYears) : (actualSavings / averageTyreLifeInYears);
//       let monthlySavings = annualSavings / 12; 
//       let timeToSavings = priceValueOneSecond === 0 ? tyrePriceInput - averageTyrePriceSecond : priceValueOneSecond - averageTyrePriceSecond;
//       let avoidNan = (timeToSavings <= 0 ||  monthlySavings <= 0) ? 0 :timeToSavings / monthlySavings ;
//       let actualTimeToSavings = avoidNan <= 0  ? 0 : Math.ceil(timeToSavings / monthlySavings);
      
//       return (
//             <tr>
//                 <td  style={{backgroundColor: "#0000FF", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td>
//                 <td><input type="number" style={{width: "50%", minWidth: "70px"}} onChange={(e) => setPriceValueOneSecond(e.target.value)} value={priceValueOneSecond === 0 ? tyrePriceInput : priceValueOneSecond}></input></td>
//                 <td>{wearRate}%</td>
//                 <td>£{(annualSavings / 12).toFixed(2)}</td>
//                 <td>£{annualSavings.toFixed(2)}</td>
//                 <td>£{priceValueOneSecond === 0 ? defaultPriceValue.toFixed(2) : actualSavings.toFixed(2)}</td>
//                 {/* <td>145</td> */}
//                 <td>{actualTimeToSavings}</td>
//                 {condotional}
//             </tr>
//             )
        

//       }else if (i === 1 && wearRate.length > 0){
        
//       actualSavings = ((averageTyrePriceSecond * percentageCalcNum) - priceValueTwoSecond);
//         let annualSavings = priceValueTwoSecond === 0 ? (defaultPriceValue / averageTyreLifeInYears) : (actualSavings / averageTyreLifeInYears);
//         let monthlySavings = annualSavings / 12; 
//         let timeToSavings = priceValueTwoSecond === 0 ? tyrePriceInput - averageTyrePriceSecond : priceValueTwoSecond - averageTyrePriceSecond;
//         let avoidNan = (timeToSavings <= 0 ||  monthlySavings <= 0) ? 0 :timeToSavings / monthlySavings ;
//         let actualTimeToSavings = avoidNan <= 0  ? 0 : Math.ceil(timeToSavings / monthlySavings);
      

//         return (
//           <tr >
//               <td  style={{backgroundColor: "#0096FF", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td>
//               <td ><input type="number" style={{width: "50%", minWidth: "70px"}} onChange={(e) => setPriceValueTwoSecond(e.target.value)} value={priceValueTwoSecond === 0 ? tyrePriceInput : priceValueTwoSecond}></input></td>
//               <td >{wearRate}%</td>
//               <td >£{(annualSavings / 12).toFixed(2)}</td>
//               <td >£{annualSavings.toFixed(2)}</td>
//               <td >£{priceValueTwoSecond === 0 ? defaultPriceValue.toFixed(2) : actualSavings.toFixed(2)}</td>
//               {/* <td>145</td> */}
//               <td >{actualTimeToSavings}</td>
//               {condotional}
//           </tr>
//         )
        
//       }else if (i === 2 && wearRate.length > 0){
//       actualSavings = ((averageTyrePriceSecond * percentageCalcNum) - priceValueThreeSecond);
//         let annualSavings = priceValueThreeSecond === 0 ? (defaultPriceValue / averageTyreLifeInYears) : (actualSavings / averageTyreLifeInYears);
//         let monthlySavings = annualSavings / 12; 
//         let timeToSavings = priceValueThreeSecond === 0 ? tyrePriceInput - averageTyrePriceSecond : priceValueThreeSecond - averageTyrePriceSecond;
//         let avoidNan = (timeToSavings <= 0 ||  monthlySavings <= 0) ? 0 :timeToSavings / monthlySavings ;
//         let actualTimeToSavings = avoidNan <= 0  ? 0 : Math.ceil(timeToSavings / monthlySavings);
//         return (
//           <tr>
//               <td  style={{backgroundColor: "#87CEEB", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td>
//               <td ><input type="number" style={{width: "50%", minWidth: "70px"}} onChange={(e) => setPriceValueThreeSecond(e.target.value)} value={priceValueThreeSecond === 0 ? tyrePriceInput : priceValueThreeSecond}></input></td>
//               <td >{wearRate}%</td>
//               <td >£{(annualSavings / 12).toFixed(2)}</td>
//               <td >£{annualSavings.toFixed(2)}</td>
//               <td >£{priceValueThreeSecond === 0 ? defaultPriceValue.toFixed(2) : actualSavings.toFixed(2)}</td>
//               {/* <td>145</td> */}
//               <td >{actualTimeToSavings}</td>
//               {condotional}
//           </tr>
//         )
        
//       }else {
//         if(wearRate.length > 0){
          
//       actualSavings = ((averageTyrePriceSecond * percentageCalcNum) - priceValueFourSecond);
//       let annualSavings = priceValueFourSecond === 0 ? (defaultPriceValue / averageTyreLifeInYears) : (actualSavings / averageTyreLifeInYears);
//       let monthlySavings = annualSavings / 12; 
//       let timeToSavings = priceValueFourSecond === 0 ? tyrePriceInput - averageTyrePriceSecond : priceValueFourSecond - averageTyrePriceSecond;
//       let avoidNan = (timeToSavings <= 0 ||  monthlySavings <= 0) ? 0 :timeToSavings / monthlySavings ;
//       let actualTimeToSavings = avoidNan <= 0  ? 0 : Math.ceil(timeToSavings / monthlySavings);  
//      return (
//           <tr>
//               <td  style={{backgroundColor: "#808080", color: "#ffffff", fontWeight: "bold"}}>{tyreBrand}</td>
//               <td ><input type="number" style={{width: "50%", minWidth: "70px"}} onChange={(e) => setPriceValueFourSecond(e.target.value)} value={priceValueFourSecond === 0 ? tyrePriceInput : priceValueFourSecond}></input></td>
//               <td >{wearRate}%</td>
//               <td >£{(annualSavings / 12).toFixed(2)}</td>
//               <td >£{annualSavings.toFixed(2)}</td>
//               <td >£{priceValueFourSecond === 0 ? defaultPriceValue.toFixed(2) : actualSavings.toFixed(2)}</td>
//               {/* <td>145</td> */}
//               <td >{actualTimeToSavings}</td>
//               {condotional}
//           </tr>
//         )
//         }
//       }
//   })

//   setCreateTableRowsTwo(trArray);
// }

// },[tableRowsArrayTwo, priceValueOneSecond, priceValueTwoSecond, priceValueThreeSecond, priceValueFourSecond, averageTyrePriceSecond, storedMileage]);


useEffect(() => {
  const tyreDataInfo = tyreData.map((ele, index) => {
    let eleWidth = ele["Width"];
    let eleRatio = ele["Ratio"];
    let eleRim = ele["Rim"];
    let eleSpeed = ele["Speed"];

    const isSelectedOption = eleWidth === bestSizeFrontWidth && eleRatio === bestSizeFrontRatio && eleRim === bestSizeFrontRim && eleSpeed === bestSpeedFront;

      return (
        <option
          key={`${eleWidth}-${eleRatio}-${eleRim}-${eleSpeed}-${index}`}
          value={[eleWidth, eleRatio, eleRim, eleSpeed]}
          selected={isSelectedOption}
        >
          {eleWidth} / {eleRatio} / {eleRim} / {eleSpeed}
        </option>
      );
  });

  setUnpackedTyreData(tyreDataInfo);
}, [tyreData]);



  const handleClickValues = [clearData, setIsLoading, apiKey, baseUrl, message, setModel, setRegistration, setFleetEdi, setBestSizeFrontWidth, setBestSizeFrontRatio, setBestSpeedFront, setIsDifferentTyres, setBestSizeFrontRim, setBestSizeRearWidth, setBestSizeRearRatio, setBestSizeRearRim, setBestSpeedRear, setTyreData, navigate, setInvalidPlate, setNoVehicleData, setTyresAreDifferent];
  const handleDataValues = [setIsLoading, setTableRowsArray, setTableRowsArrayTwo, apiKey, chartsBaseUrl, fleetEdi, dropdownWidthValue, dropdownRatioValue, dropdownRimValue, dropdownSpeedValue, loggedInUser, message, setAverageTyrePrice, tyresAreDifferent, dropdownWidthValueSecond, dropdownRatioValueSecond, dropdownRimValueSecond, dropdownSpeedValueSecond, setAverageTyrePriceSecond, navigate, setChartsArray ];
  const handleWrongVehicleValues = [clearData, setIsLoading, apiKey, baseUrl, message, setModel, setRegistration, setFleetEdi, setBestSizeFrontWidth, setBestSizeFrontRatio, setBestSizeFrontRim, setBestSpeedFront, setIsDifferentTyres,setBestSizeRearWidth, setBestSizeRearRatio, setBestSizeRearRim, setBestSpeedRear, setTyreData, setInvalidPlate, setNoVehicleData];


// Function value passes

  return (
    <>
        <LoginContext.Provider value={{userIsLoggedIn, setUserIsLoggedIn}}>
        <Layout setApiKey={setApiKey} setUsername={setUsername}>

         <Routes>
          <Route exact path="/" element={
          <Login 
            handleLogin={handleLogin}
            username={username}
            password={password}
            setUsername={setUsername}
            setPassword={setPassword}
            isAlertVisible={isAlertVisible}
            
          />
          } />
          <Route element={<PrivateRoutes />}>
          <Route path="/dashboard-page" element={
          <Dashboard 
             message={message}
             setMessage={setMessage}
             invalidPlate={invalidPlate}
             noVehicleData={noVehicleData}
             isLoading={isLoading}
             apiKey={apiKey}
             setFleetEdi={setFleetEdi}
             setDropdownWidthValue={setDropdownWidthValue}
             setDropdownRatioValue={setDropdownRatioValue}
             setDropdownRimValue={setDropdownRimValue}
             setDropdownSpeedValue={setDropdownSpeedValue}
             setIsFrontTyre={setIsFrontTyre}
            handleDataValues={handleDataValues}
             setRegistration={setRegistration}
             loggedInUser={loggedInUser}
     
             handleClickValues={handleClickValues}
             
          />} />

          <Route path="/tyre-data" element={
          <TyreData
            unpackedTyreData={unpackedTyreData}
            model={model}
            registration={registration}
            bestSizeFrontWidth={bestSizeFrontWidth}
            bestSizeFrontRatio={bestSizeFrontRatio}
            bestSizeFrontRim={bestSizeFrontRim}
            bestSizeRearWidth={bestSizeRearWidth}
            bestSizeRearRatio={bestSizeRearRatio}
            bestSizeRearRim={bestSizeRearRim}
            bestSpeedFront={bestSpeedFront}
            bestSpeedRear={bestSpeedRear}
            isDifferentTyres={isDifferentTyres}
            handleDataValues={handleDataValues}

            //-----------------------------------
            handleSelectOption={handleSelectOption}
            handleSelectOptionTwo={handleSelectOptionTwo}
            handleDefaultSelectOption={handleDefaultSelectOption}
            handleDefaultSelectOptionTwo={handleDefaultSelectOptionTwo}
            getRatioValues={getRatioValues}
            getRimValues={getRimValues}
            getSpeedValues={getSpeedValues}

            isTrue={isTrue}
            isTrueSecond={isTrueSecond}
            widthHTMLElement={widthHTMLElement}
            ratioHTMLElement={ratioHTMLElement}
            rimHTMLElement={rimHTMLElement}
            isFrontTyre={isFrontTyre}
            setIsFrontTyre={setIsFrontTyre}
            speedHTMLElement={speedHTMLElement}
            //-------------------------------
            controlledSpeedVal={controlledSpeedVal}
            presetVal={presetVal}
            presetValTwo={presetValTwo}
            setPresetVal={setPresetVal}
            setPresetValTwo={setPresetValTwo}
            widthVal={widthVal}
            ratioVal={ratioVal}
            rimVal={rimVal}
            speedVal={speedVal}
            handleWrongVehicleValues={handleWrongVehicleValues}
            isLoading={isLoading}
            navigate={navigate}

            tyresAreDifferent={tyresAreDifferent}
            setTyresAreDifferent={setTyresAreDifferent}

            widthValSecond={widthValSecond}
            getRatioValuesSecond={getRatioValuesSecond}
            widthHTMLElementSecond={widthHTMLElementSecond}
            ratioValSecond={ratioValSecond}
            getRimValuesSecond={getRimValuesSecond}
            ratioHTMLElementSecond={ratioHTMLElementSecond}
            rimValSecond={rimValSecond}
            getSpeedValuesSecond={getSpeedValuesSecond}
            rimHTMLElementSecond={rimHTMLElementSecond}
            speedValSecond={speedValSecond}
            controlledSpeedValSecond={controlledSpeedValSecond}
            speedHTMLElementSecond={speedHTMLElementSecond}
            
           />}/>

           <Route path="/charts" element={
           <Charts 
              createTableRows={createTableRows}
              createTableRowsTwo={createTableRowsTwo}
              dropdownWidthValue={dropdownWidthValue}
              dropdownRatioValue={dropdownRatioValue}
              dropdownRimValue={dropdownRimValue} 
              dropdownSpeedValue={dropdownSpeedValue}
              dropdownWidthValueSecond={dropdownWidthValueSecond}
              dropdownRatioValueSecond={dropdownRatioValueSecond}
              dropdownRimValueSecond={dropdownRimValueSecond}
              dropdownSpeedValueSecond={dropdownSpeedValueSecond}

              model={model}
              registration={registration}
              isFrontTyre={isFrontTyre}
              tableRowsArray={tableRowsArray}
              tableRowsArrayTwo={tableRowsArrayTwo}
              newQueryRequest={newQueryRequest}
              navigate={navigate}
              // historySearchPressed={historySearchPressed}
              averageTyrePrice={averageTyrePrice}
              averageTyrePriceSecond={averageTyrePriceSecond}
              setAverageTyrePrice={setAverageTyrePrice}
              setAverageTyrePriceSecond={setAverageTyrePriceSecond}

              mileageInput={mileageInput}
              setMileageInput={setMileageInput}
              storedMileage={storedMileage}
              setStoredMileage={setStoredMileage}
              tyresAreDifferent={tyresAreDifferent}
              tyreWearRateAll={tyreWearRateAll}
              // tyreWearRateRear={tyreWearRateRear}
              chartsArray={chartsArray}
              setChartsArray={setChartsArray}
              suggestedFitments={suggestedFitments}
              tyreComparisonRequestsID={tyreComparisonRequestsID}
              url={url}
              apiKey={apiKey}
           />} /> 

           </Route>
          </Routes>
        </Layout>
        </LoginContext.Provider>
    </>
);
}

export default App;
