import Header from './Header/Header';
import Footer from './Footer/Footer';
import { useContext } from "react";
import { LoginContext } from "../Context/LoginContext";

function Layout(props) {
  const { userIsLoggedIn, setUserIsLoggedIn } = useContext(LoginContext);
  return (
    <>
      <div>
            <Header userIsLoggedIn={userIsLoggedIn} setUserIsLoggedIn={setUserIsLoggedIn} setApiKey={props.setApiKey} />
            {props.children}
      </div>
       <Footer />
    </>
  );
}

export default Layout;