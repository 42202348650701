export const HandleClickWrongVehicle = (props) => {
    
  let [clearData, setIsLoading, apiKey, baseUrl, message, setModel, setRegistration, setFleetEdi, setBestSizeFrontWidth, setBestSizeFrontRatio, setBestSizeFrontRim, setBestSpeedFront, setIsDifferentTyres,setBestSizeRearWidth, setBestSizeRearRatio, setBestSizeRearRim, setBestSpeedRear, setTyreData, setInvalidPlate] = props;
    clearData();
    setIsLoading(true);
    
    // We need to secure these authentication details 
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${apiKey}`);
  
  
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };
  
    fetch(`${baseUrl}${message}&useExisting=false`, requestOptions)
        .then(response => response.json())
        .then(data => {
          
          // Unpacking data coming as an object
          const tyreSizeUnpacked = data["TyreSizeOptions"];
          const bestSizeFront = data["TyreSizeStandardFront"]
          const bestSizeBack = data["TyreSizeStandardRear"]
  
          const fleetNetEdi = data["FleetNetEDI"];
          
          // Checking if we have valid reg
          if(fleetNetEdi !== 0){
            // Setting Card information variables
            setModel(data["Model"]);
            setRegistration(data["Registration"]);
            setFleetEdi(fleetNetEdi);
  
            if(data["TyreSizeDifferent"] === false){
              
              // Setting information about most used size using for all wheels
              setBestSizeFrontWidth(bestSizeFront["Width"]);
              setBestSizeFrontRatio(bestSizeFront["Ratio"]);
              setBestSizeFrontRim(bestSizeFront["Rim"]);
              setBestSpeedFront(bestSizeFront["Speed"]);
              setIsDifferentTyres(true);
              
            }else{
              
              // Setting information about most used size for front wheels
              setBestSizeFrontWidth(bestSizeFront["Width"]);
              setBestSizeFrontRatio(bestSizeFront["Ratio"]);
              setBestSizeFrontRim(bestSizeFront["Rim"]);
              setBestSpeedFront(bestSizeFront["Speed"]);
              // Setting information about most used size for rear wheels 
              setBestSizeRearWidth(bestSizeBack["Width"]);
              setBestSizeRearRatio(bestSizeBack["Ratio"]);
              setBestSizeRearRim(bestSizeBack["Rim"]);
              setBestSpeedRear(bestSizeBack["Speed"]);
  
              setIsDifferentTyres(false);
  
            }
            
            // Populating the options for dropdown menu 
            tyreSizeUnpacked.forEach(line => { 
              setTyreData(oldState => [...oldState, line])
            })
          }
          else{
            setInvalidPlate(true);
          }
          
          setIsLoading(false);
        })
        .catch(error => console.log(`TESTING ERROR: ${error}`))  
        
  }; 