import Card from 'react-bootstrap/Card';


export const CardHeaderCharts = ({
    model,
    registration,
    dropdownWidthValue,
    dropdownRatioValue,
    dropdownRimValue,
    dropdownSpeedValue,
    isFrontTyre,
    tyresAreDifferent,
    dropdownWidthValueSecond,
    dropdownRatioValueSecond,
    dropdownRimValueSecond,
    dropdownSpeedValueSecond,
}) => {
    const textCenterStyle = {
        backgroundColor: "#f8d038",
        margin: "0 250px 0 250px", 
        padding: "8px",
        fontWeight: "bold", fontFamily: "uknumberplateregular", 
        fontSize: "25px", 
        border: "2px solid black", 
        borderRadius: "5px",
        minWidth: "30%",
        minHeight: "20%",
     }

    return (
        <>
            <Card border="secondary" style={{ width: '45rem' }}>
                <Card.Header className="text-center" style={textCenterStyle}>{registration}</Card.Header>
                    <Card.Body>
                    <Card.Title><b>{model}</b></Card.Title>
                    {!tyresAreDifferent 
                    ? 
                    <Card.Text>
                        <b>Size Selected All:</b> {dropdownWidthValue} / {dropdownRatioValue} / {dropdownRimValue} / {dropdownSpeedValue}  
                    </Card.Text>
                    :
                    <>
                    <Card.Text>
                        <b>Size Selected Front:</b> {dropdownWidthValue} / {dropdownRatioValue} / {dropdownRimValue} / {dropdownSpeedValue}  
                    </Card.Text>
                    <Card.Text>
                    <b>Size Selected Rear:</b> {dropdownWidthValueSecond} / {dropdownRatioValueSecond} / {dropdownRimValueSecond} / {dropdownSpeedValueSecond}  
                    </Card.Text>
                    </>
                    }
                    
                    
             </Card.Body>
      </Card>
        </>
    )
}