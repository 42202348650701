
import { useState, useEffect } from "react";

const WidthDropdownFunc = () => {

    const widthUrl = `https://dataengine.paymonthlytyres.com/api/Vehicle/Tyres/GetWidthOptions`;

    const [apiKey, setApiKey] = useState(window.localStorage.getItem("API_KEY"));

    const [width, setWidth] = useState([]);
    // const [widthVal, setWidthVal] = useState("");
    const [widthHTMLElement, setWidthHTMLElement] = useState([]);

    function getWidthValues(){
        setWidth([]);
        // setDropdownWidthValue(e.target.value)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic ${apiKey}`);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        fetch(widthUrl, requestOptions)
          .then(response => response.json())
          .then(result => {
            
            Object.values(result).forEach(value => {
              setWidth(oldState => [...oldState, value])
            })
          })
          .catch(error => console.log('error', error));
      };

    
      useEffect(() => {
    
        const widthInfo = width.map((ele, index) => {
          
          return (
              <option key={`width-${index}`} value={ele["Value"]}>{ele["Value"]}</option>
          )
        })
        setWidthHTMLElement(widthInfo)
      },[width])

      return {
        getWidthValues,
        widthHTMLElement,
      }
}

export default WidthDropdownFunc;