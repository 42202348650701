import { useState, useEffect } from "react";

const AllDropdownsRear = () => {

    const [apiKey, setApiKey] = useState(window.localStorage.getItem("API_KEY"));
    const ratioUrl = `https://dataengine.paymonthlytyres.com/api/Vehicle/Tyres/GetRatioOptions?width=`;
    const rimUrl = `https://dataengine.paymonthlytyres.com/api/Vehicle/Tyres/GetRimOptions?width=`;
    const speedUrl = `https://dataengine.paymonthlytyres.com/api/Vehicle/Tyres/GetSpeedOptions?width=`;

    const [widthValSecond, setWidthValSecond] = useState("");
    const [ratio, setRatio] = useState([]);
    const [ratioHTMLElementSecond, setRatioHTMLElementSecond] = useState([])
    const [dropdownWidthValueSecond, setDropdownWidthValueSecond] = useState("");

    const [ratioValSecond, setRatioValSecond] = useState("");
    const [dropdownRatioValueSecond, setDropdownRatioValueSecond] = useState("");

    const [rim, setRim] = useState([]);
    const [rimHTMLElementSecond, setRimHTMLElementSecond] = useState([])

    const [speed, setSpeed] = useState([]);
    const [speedHTMLElementSecond, setspeedHTMLElementSecond] = useState([])

    const [rimValSecond, setRimValSecond] = useState("");
    const [speedValSecond, setSpeedValSecond] = useState("");
    const [dropdownRimValueSecond, setDropdownRimValueSecond] = useState("");
    const [dropdownSpeedValueSecond, setDropdownSpeedValueSecond] = useState("");

    
// setWVal needs out 
   const RatioDropdownFuncSecond = () => {
    

    function getRatioValuesSecond(e){
        setRatio([])
        setWidthValSecond("");     
        setWidthValSecond(e.target.value);
        setDropdownWidthValueSecond(e.target.value);
        
        var myHeaders = new Headers();
          myHeaders.append("Authorization", `Basic ${apiKey}`);
    
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
    
          fetch(`${ratioUrl}${e.target.value}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Object.values(result).forEach(line => {
                  setRatio(oldState => [...oldState, line])
                })
            })
    
        .catch(error => console.log('error', error));
      };

      useEffect(() => {
    
        const ratioInfo = ratio.map((ele, index) => {
          return (
              <option key={`ratio-second-${index}`} value={ele["Value"]}>{ele["Value"]}</option>
          )
        })
        setRatioHTMLElementSecond(ratioInfo)
    },[ratio]);
    

      return {
        widthValSecond,
        setWidthValSecond,
        dropdownWidthValueSecond,
        setDropdownWidthValueSecond,
        ratioHTMLElementSecond,
        getRatioValuesSecond,
        
      }
}

 const RimDropdownFuncSecond = () => {

    

    function getRimValuesSecond(e){
        setRim([]);
        setRatioValSecond(e.target.value)
        setDropdownRatioValueSecond(e.target.value)
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic ${apiKey}`);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        fetch(`${rimUrl}${widthValSecond}&ratio=${e.target.value}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            
            Object.values(result).forEach(line => {
              setRim(oldState => [...oldState, line])
            })
          })
          .catch(error => console.log('error', error));
      };


      useEffect(() => {
    
        const rimInfo = rim.map((ele, index) => {
          return (
              <option key={`rim-second-${index}`} value={ele["Value"]}>{ele["Value"]}</option>
          )
        })
        setRimHTMLElementSecond(rimInfo)
      },[rim]);

      return {
        ratioValSecond,
        setRatioValSecond,
        dropdownRatioValueSecond,
        setDropdownRatioValueSecond,
        rimHTMLElementSecond,
        getRimValuesSecond,
      }
}

 const SpeedDropdownFuncSecond = () => {


  function getSpeedValuesSecond(e){
    setSpeed([]);
    setRimValSecond(e.target.value);
    setDropdownRimValueSecond(e.target.value);

    var myHeaders = new Headers();
      myHeaders.append("Authorization", `Basic ${apiKey}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${speedUrl}${widthValSecond}&ratio=${ratioValSecond}&rim=${e.target.value}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          Object.values(result).forEach(line => {
            setSpeed(oldState => [...oldState, line])
          })
        })
        .catch(error => console.log('error', error));

  }

  function controlledSpeedValSecond(e){
    setSpeedValSecond(e.target.value);
    setDropdownSpeedValueSecond(e.target.value);
  }

  useEffect(() => {
    
    const speedInfo = speed.map((ele, index) => {
      return (
          <option key={`speed-second-${index}`} value={ele["Value"]}>{ele["Value"]}</option>
      )
    })
    setspeedHTMLElementSecond(speedInfo)
  },[speed]);

  return {
    speedHTMLElementSecond,
    rimValSecond,
    setRimValSecond,
    speedValSecond,
    setSpeedValSecond,
    dropdownRimValueSecond,
    setDropdownRimValueSecond,
    dropdownSpeedValueSecond,
    setDropdownSpeedValueSecond,
    getSpeedValuesSecond,
    controlledSpeedValSecond,
  }
 }


 return {
    RatioDropdownFuncSecond,
    RimDropdownFuncSecond,
    SpeedDropdownFuncSecond,
}
}

    


export default AllDropdownsRear