import './Login.css';
import { useState } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import loginLogo from '../Pictures/loginImgCompress.jpg'
import Alert from 'react-bootstrap/Alert';

import {  useContext } from "react";
import { LoginContext } from "../Context/LoginContext";
import {useNavigate} from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';

import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBInput,
  }
  from 'mdb-react-ui-kit';

function Login ({
  handleLogin,
  username,
  password,
  setUsername,
  setPassword,
  isAlertVisible,
  
}) {
  const { userIsLoggedIn } = useContext(LoginContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const getUsername = (e) => {
    setUsername(e.target.value)
  }
  const getPassword = (e) => {
    setPassword (e.target.value)
  }
  
    return (
        <>
        {!userIsLoggedIn 
        ?

        <MDBContainer className='my-5'>
      <MDBCard>

        <MDBRow className='g-0 d-flex align-items-center'>

          <MDBCol md='4'>
            <MDBCardImage src={loginLogo} alt='phone' className='rounded-t-5 rounded-tr-lg-0' fluid />
          </MDBCol>

          <MDBCol md='8'>
              
            <MDBCardBody className='test'>
        

            {/* // square border border-danger */}
              <MDBInput wrapperClass='mb-4' label='Email address'  id='form1' type='email' onChange={getUsername} value={username}/>
              <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' onChange={getPassword} value={password}/>
              <div className="d-flex justify-content-between mx-4 mb-4">
                {/* <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' /> */}
                {/* <a href="!#">Forgot password?</a> */}
              </div>

             {username !== "" && password !=="" ? <MDBBtn className="mb-4 w-100" type='submit' onClick={handleLogin}>Login</MDBBtn> : <MDBBtn className="mb-4 w-100"  disabled>Login</MDBBtn> } 
             {isAlertVisible && <Alert key="danger" variant="danger">Wrong username or password</Alert>} 

            
      <span
        onClick={() => setOpen(!open)}
        aria-controls="example-fade-text"
        aria-expanded={open}
        className='loginProblems'
      >
        Problems logging in?
      </span>
      <Fade in={open}>
        <div id="example-fade-text" ><span className='loginProblemsEmail'>Please email: highmileageheroesaccess@driverhelpline.co.uk</span></div>
      </Fade>
  
            </MDBCardBody>

          </MDBCol>

        </MDBRow>

      </MDBCard>
    </MDBContainer>
    :
    <MDBContainer className='my-5'>
      <MDBCard>

        <MDBRow className='g-0 d-flex align-items-center'>

          <MDBCol md='4'>
            <MDBCardImage src={loginLogo} alt='tyre' className='rounded-t-5 rounded-tr-lg-0' fluid />
          </MDBCol>

          <MDBCol md='8'>

            <MDBCardBody>

              <div className="d-flex justify-content-between mx-4 mb-4">
              <h4>You are currently logged in</h4>
              </div>
              <MDBBtn className="mb-4 w-100" onClick={() => navigate("/dashboard-page")}>Proceed</MDBBtn>

            </MDBCardBody>

          </MDBCol>

        </MDBRow>

      </MDBCard>
    </MDBContainer>
       }
           </>
    )

};


export default Login;