export const DropdownOptions = () => {
    return (
        <>
              {/* <option value="2">Extremely Used (2mm)</option> */}
              <option value="3">(3mm)</option>
              <option value="4">(4mm)</option>
              <option value="5">(5mm)</option>
              <option value="6">(6mm)</option>
              <option value="7">(7mm)</option>
              <option value="8">(8mm)</option>
              </>
    )
}

export const DropdownManufs = () => {
    return (
              <>
              <option value="Budget">Budget</option>
              <option value="Premium">Premium</option>
              {/* <option value="t">Total</option> */}
              </>
    )
}