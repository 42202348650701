import Bridgestone from '../Pictures/bridgestone.png';
import Continental from '../Pictures/continental.png';
import Dunlop from '../Pictures/dunlop.png';
import Goodyear from '../Pictures/goodyear.png';
import Hankook from '../Pictures/hankook.png';
import Michelin from '../Pictures/michelin.png';
import Yokohama from '../Pictures/yokohama.png';
import Pirelli from '../Pictures/pirelli.png';

export default function CompanyLogo({imageSource, logo}) {
  return (
    <div style={{display: "inline-block"}}>
      <img  src={imageSource === 'Bridgestone' ? Bridgestone : imageSource === 'Continental' ? Continental : imageSource === 'Dunlop' ? Dunlop : imageSource === 'Hankook' ? Hankook : imageSource === 'Goodyear' ? Goodyear : imageSource === 'Michelin' ? Michelin : imageSource === 'Yokohama' ? Yokohama : imageSource === 'Pirelli' ? Pirelli : ''} className='imgLogo' alt={imageSource} style={{width: logo === false ? "50px" : "120px", height: "auto"}} />
    </div>
  );
}