// export const HandleData = (props) => {
//     const [setIsLoading, setTableRowsArray, setTableRowsArrayTwo, apiKey, chartsBaseUrl, fleetEdi, dropdownWidthValue, dropdownRatioValue, dropdownRimValue, dropdownSpeedValue, loggedInUser, message, setAverageTyrePrice, tyresAreDifferent, dropdownWidthValueSecond, dropdownRatioValueSecond, dropdownRimValueSecond, dropdownSpeedValueSecond, setAverageTyrePriceSecond, navigate ] = props;

//     setIsLoading(true);
      
//     setTableRowsArray([]);
//     setTableRowsArrayTwo([]);
//     var myHeaders = new Headers();
//         myHeaders.append("Authorization", `Basic ${apiKey}`);

//         var requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         redirect: 'follow'
//         };
//         let firstFetchUrl = `${chartsBaseUrl}${fleetEdi}&tyreWidth=${dropdownWidthValue}&tyreRatio=${dropdownRatioValue}&tyreRim=${dropdownRimValue}&tyreSpeedRating=${dropdownSpeedValue}&annualMileage=0&tyreIsFront=true&requestBy=${loggedInUser}&vehicleReg=${message}`;
//         fetch(firstFetchUrl, requestOptions)
//         .then(response => response.json())
//         .then(result => {
//             const unpackedData = JSON.parse(result)
//             // table header data 
            
//             if(unpackedData.length > 1){
//               const td = unpackedData.slice(1);
              
//             if(td[0][0] === "Budget"){
//                 td.push(td.shift());
//               }
//               td.forEach((line, i) => {
//                  if(i === 0){
//                   setAverageTyrePrice(Number(line[3]));
//                  }
//                   setTableRowsArray(oldState => [...oldState, line]);
//               })
              
//             }else {
//               setTableRowsArray(['No data', '0', '0', '0', '0'])
//             }

//            })
//       .catch(error => console.log('error', error));

//       // Rear Tyre Fetch request 
//       let secondFetchUrl;
//       if (tyresAreDifferent === false){
//         secondFetchUrl = `${chartsBaseUrl}${fleetEdi}&tyreWidth=${dropdownWidthValue}&tyreRatio=${dropdownRatioValue}&tyreRim=${dropdownRimValue}&tyreSpeedRating=${dropdownSpeedValue}&annualMileage=0&tyreIsFront=false&requestBy=${loggedInUser}&vehicleReg=${message}`;;
//       }else {
//         secondFetchUrl =`${chartsBaseUrl}${fleetEdi}&tyreWidth=${dropdownWidthValueSecond}&tyreRatio=${dropdownRatioValueSecond}&tyreRim=${dropdownRimValueSecond}&tyreSpeedRating=${dropdownSpeedValueSecond}&annualMileage=0&tyreIsFront=false&requestBy=${loggedInUser}&vehicleReg=${message}`
//       }
      

//       fetch(secondFetchUrl, requestOptions)
//         .then(response => response.json())
//         .then(result => {
//             const unpackedData = JSON.parse(result)
//             // table header data 
            

//             if(unpackedData.length > 1){
//               const tdTwo = unpackedData.slice(1);
              
//             if(tdTwo[0][0] === "Budget"){
//               tdTwo.push(tdTwo.shift());

//               }
//               tdTwo.forEach((line, i) => {
//                 if(i === 0){
//                   setAverageTyrePriceSecond(Number(line[3]));
//                  }
//                 setTableRowsArrayTwo(oldState => [...oldState, line]);
                
//             })
//             }else {
//               setTableRowsArrayTwo(['No data', '0', '0', '0', '0'])
//             }

//           setIsLoading(false);
//           navigate("/charts");

//            })
//       .catch(error => console.log('error', error));
           
// }

export const HandleData = (props) => {
  const [setIsLoading, setTableRowsArray, setTableRowsArrayTwo, apiKey, chartsBaseUrl, fleetEdi, dropdownWidthValue, dropdownRatioValue, dropdownRimValue, dropdownSpeedValue, loggedInUser, message, setAverageTyrePrice, tyresAreDifferent, dropdownWidthValueSecond, dropdownRatioValueSecond, dropdownRimValueSecond, dropdownSpeedValueSecond, setAverageTyrePriceSecond, navigate, setChartsArray ] = props;
    setIsLoading(true);
      
    setTableRowsArray([]);
    setTableRowsArrayTwo([]);
    setChartsArray([]);
    
   
    var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic ${apiKey}`);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
        };
        let fetchUrl = "";
        if (tyresAreDifferent === false){
          fetchUrl = `${chartsBaseUrl}${fleetEdi}&tyreWidth=${dropdownWidthValue}&tyreRatio=${dropdownRatioValue}&tyreRim=${dropdownRimValue}&tyreSpeedRating=${dropdownSpeedValue}&tyreWidthRear=${dropdownWidthValue}&tyreRatioRear=${dropdownRatioValue}&tyreRimRear=${dropdownRimValue}&tyreSpeedRatingRear=${dropdownSpeedValue}&requestBy=${loggedInUser}&vehicleReg=${message}`;
        }else {
          fetchUrl =`${chartsBaseUrl}${fleetEdi}&tyreWidth=${dropdownWidthValue}&tyreRatio=${dropdownRatioValue}&tyreRim=${dropdownRimValue}&tyreSpeedRating=${dropdownSpeedValue}&tyreWidthRear=${dropdownWidthValueSecond}&tyreRatioRear=${dropdownRatioValueSecond}&tyreRimRear=${dropdownRimValueSecond}&tyreSpeedRatingRear=${dropdownSpeedValueSecond}&requestBy=${loggedInUser}&vehicleReg=${message}`;
              }
        fetch(fetchUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            const unpackedData = JSON.parse(result)
          let averageFrontPrice = 0
            let averageRearPrice= 0
            if(unpackedData.length > 1){
              const td = unpackedData.slice(1);
              
            if(td[0][0] === "Budget"){
                td.push(td.shift());
              }
              setTableRowsArray(oldState => (oldState, [[td[0][0],'Average', '0', '0', '0', '0', '0', '0', '0', '0']]))
              
              td.forEach((line, i) => {
                 
                let frontPrice = line[4];
                let rearPrice = line[5];
          
                let newFrontPrice = frontPrice;
                let newRearPrice = rearPrice;
          
                if(frontPrice.length === 0 ){
                  if(rearPrice !== 0){
                    newFrontPrice = newRearPrice;
                  }else{
                    newFrontPrice = '1';
                  }
                }
                if(rearPrice.length === 0 ){
                  if(frontPrice.length){
                    newRearPrice = frontPrice
                  }else {
                    newRearPrice = '1';
                  }
                }
                averageFrontPrice += Number(newFrontPrice) * 2;
                averageRearPrice += Number(newRearPrice) * 2;
                // averageTyrePrice+= Number(line[3])
                
                  setTableRowsArray(oldState => [...oldState, line]);
              })
                const averageHandlerFront = averageFrontPrice / (td.length);
                const averageHandlerRear = averageRearPrice / (td.length);
            
                const totalPrice = Math.floor(averageHandlerFront + averageHandlerRear);
                setAverageTyrePrice(totalPrice);
               
                
            }else {
              setTableRowsArray(['No data', '0', '0', '0', '0'])
            }
            setIsLoading(false);
            navigate("/charts");
           })
      .catch(error => console.log('error', error));
}