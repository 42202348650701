export const TableHead = () =>{
    return (
        <thead>
            <tr>
              <th><b>Tyre Brand</b></th>
              {/* <th></th> */}
              {window.localStorage.getItem("userName") === "stoyan" || window.localStorage.getItem("userName") === "hmhprice" ?<><th>Tyre Price Value</th><th>Average Value</th></> : ''}
              {/* <th><b>Tyre Price £</b></th> */}
              <th><b>Tyre Life Compared to Average</b></th>
              {/* <th><b>Monthly Savings</b></th> */}
              <th><b>Cost Saving Over Life Of All Tyres</b></th>
              <th><b>Annual Savings</b></th>
              {/* <th><b>Days Remaining Current Tyre</b></th> */}
              {/* <th><b>Months To Saving Money</b></th> */}
              <th><b>Credibility</b></th>
            </tr>
          </thead>
    )
}

