import './Dashboard.css'
import {useState, useEffect} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { MDBBtn } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import { HandleClick } from '../Functions/HandleClick/HandleClick';

function Dashboard({
  isLoading,
  message,
  setMessage,
  handleClickValues,
  invalidPlate,
  noVehicleData
}) {
  const [searchInput, setSearchInput] = useState('');
  const [isInputField, setIsInputField] = useState(true);

        function handleChange(e) {
            const inputValue = e.target.value;
            const regex = /^[a-zA-Z0-9]*$/; 
            if (regex.test(inputValue)) {
              setMessage(inputValue);
            }
          }
//   const handleInputChange = (event) => {
//     const { value } = event.target;
//     setSearchInput(value.toUpperCase().substring(0, 7));
//   }
  
  useEffect(() => {
    if(message !== "" ){
        setIsInputField(false);
     }else {
        setIsInputField(true);
     }
},[message])
  return (
    <>
    { isLoading 
        ? 
        <div>
            <div className='loadingPage'><Spinner animation="grow" /></div>
        </div> 
        : 
        <>
    <Form onSubmit={() => HandleClick(handleClickValues)} className="formField">
      <label htmlFor="search-bar" className="sr-only">
        Search by car registration number
      </label>
      <div className="search-bar-container">
      <div className="search-bar">
        <div className="search-bar__prefix">UK</div>
        <input
          type="text"
          id="search-bar"
          name="search-bar"
          placeholder="REG NUMBER"
          maxLength="7"
          value={message} 
          onChange={handleChange}
          className="search-bar__input"
          
        />
        <MDBBtn type="submit"  onClick={() => HandleClick(handleClickValues)} className="search-bar__submit" disabled={isInputField}>
          Search
        </MDBBtn>
      </div>
    { invalidPlate && <p className='alertP'>Invalid Plate !</p>}
    { noVehicleData && <p className='alertP'>No data for this vehicle !</p>}
    </div>
    </Form>
    </>
    }
    </>
  );
}

export default Dashboard;
