import { Chart } from "react-google-charts";


export const DisplayChart = ({
    arrayTyres,
    arrayWearRate,
    colorHandlerOne,
    colorHandlerTwo,
    colorHandlerThree,
}) => {
    const data = [
        arrayTyres,
        arrayWearRate,
        
       ];
      
       const options = {
        
        vAxis: { title: "Tyre Life % Compared To Average" },
        hAxis: { title: "Tyre Manufacturer" },
        seriesType: "bars",
        series: {4: {type: "line"}},
        colors: [colorHandlerOne ? "#808080" :'#0000FF', colorHandlerTwo ? "#808080" : '#0096FF', colorHandlerThree ? "#808080" : "#87CEEB", "#808080"],

      };

    return (
        <>
            <Chart
                chartType="ComboChart"
                width="100%"
                height="400px"
                data={data}
                options={options}
      />
        </>
    )
}
