import Form from 'react-bootstrap/Form';



export const ManualInputDropdown = (props) => {


    return (
        <>
            <Form.Select aria-label="Default select example" value={props.widthVal} className='manualInputOptions' onChange={props.getRatioValues}>
            <option>- -</option>
                 {props.widthHTMLElement}
            </Form.Select>

            <Form.Select aria-label="Default select example" value={props.ratioVal} className='manualInputOptions' onChange={props.getRimValues}>
            <option>- -</option>
            {props.ratioHTMLElement}
            </Form.Select>

            <Form.Select aria-label="Default select example" value={props.rimVal} className='manualInputOptions' onChange={props.getSpeedValues}>
            <option>- -</option>
            {props.rimHTMLElement}
            </Form.Select>
            <Form.Select aria-label="Default select example" value={props.speedVal}  className='manualInputOptions' onChange={props.controlledSpeedVal}>
            <option>- -</option>
            {props.speedHTMLElement}
            </Form.Select>
        </>
    )
}

export const ManualInputDropdownTwo = (props) => {
    return (
        <>
            <Form.Select aria-label="Default select example" value={props.widthValSecond} className='manualInputOptions' onChange={props.getRatioValuesSecond}>
            <option>- -</option>
                 {props.widthHTMLElementSecond}
            </Form.Select>

            <Form.Select aria-label="Default select example" value={props.ratioValSecond} className='manualInputOptions' onChange={props.getRimValuesSecond}>
            <option>- -</option>
            {props.ratioHTMLElementSecond}
            </Form.Select>

            <Form.Select aria-label="Default select example" value={props.rimValSecond} className='manualInputOptions' onChange={props.getSpeedValuesSecond}>
            <option>- -</option>
            {props.rimHTMLElementSecond}
            </Form.Select>
            <Form.Select aria-label="Default select example" value={props.speedValSecond}  className='manualInputOptions' onChange={props.controlledSpeedValSecond}>
            <option>- -</option>
            {props.speedHTMLElementSecond}
            </Form.Select>
        </>
    )
}