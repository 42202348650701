import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import loginLogos from '../../Pictures/KF.png'

function Header ({
  userIsLoggedIn,
  setUserIsLoggedIn,
  setApiKey,
  
}) {
  
  const signOut = () => {
    window.localStorage.removeItem("isLoggedIn");
    window.localStorage.removeItem("API_KEY");
    window.localStorage.removeItem("userName");
    setUserIsLoggedIn(false)
    setApiKey("")
  }
  const styles = {

    backgroundColor: 'white',
    borderRadius: '3px',
  }
    return(
        <>
        <Navbar bg="dark" variant="dark">
        <Container>
          {/* <Navbar.Brand>High Mileage Heroes</Navbar.Brand> */}
          <Navbar.Brand style={styles}><img src={loginLogos} height='35'alt='Kwik Fit logo banner' /></Navbar.Brand>
          {userIsLoggedIn && <div className='headerUsername'>
          <p>{`Hi, ${window.localStorage.getItem("userName")}`}</p>
          </div>}
          
          <Nav className="me-auto">
          </Nav>
            {userIsLoggedIn && <Button variant="outline-success" onClick={signOut}>Logout</Button> }
         </Container>
      </Navbar>
    </>

    )
 }

export default Header; 