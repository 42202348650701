
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form';
import carDrawImg from '../../Pictures/carDraw.png';
import EditIcon from '../../Pictures/editIcon.png'
import './TyreStatus.css'
import { useState, useEffect } from 'react'
import { DropdownOptions, DropdownManufs } from '../DropdownOptions/DropdownOptions';
import Modal from 'react-bootstrap/Modal';


export const CarDraw = ({
    mileageInput,
    tyreWearRateAll,
    api,
    url,
    tyreComparisonRequestsID
}) => {
 

  const [osfThread, setOsfThread] = useState("8");
  const [osfPremium, setOsfPremium] = useState(false);
  
  const [nsfThread, setNsfThread] = useState("8");
  const [nsfPremium, setNsfPremium] = useState(false);

  const [osrThread, setOsrThread] = useState("8");
  const [osrPremium, setOsrPremium] = useState(false);

  const [nsrThread, setNsrThread] = useState("8");
  const [nsrPremium, setNsrPremium] = useState(false);

    return (
        <>
        <div className='popup'>
        <div className="frontLeft">
        <Popup 
        mileageInput={mileageInput} 
        tyreWearRateAll={tyreWearRateAll} 
        text="Front Right" 
        axlePosition="frontRight" 
        api={api} 
        url={url} 
        tyreComparisonRequestsID={tyreComparisonRequestsID}
        osfThread={osfThread}
        setOsfThread={setOsfThread}
        osfPremium={osfPremium}
        setOsfPremium={setOsfPremium}
        nsfThread={nsfThread}
        setNsfThread={setNsfThread}
        nsfPremium={nsfPremium}
        setNsfPremium={setNsfPremium}
        osrThread={osrThread}
        setOsrThread={setOsrThread}
        osrPremium={osrPremium}
        setOsrPremium={setOsrPremium}
        nsrThread={nsrThread}
        setNsrThread={setNsrThread}
        nsrPremium={nsrPremium}
        setNsrPremium={setNsrPremium}
        />
        </div>

        <div className="frontRight">
        <Popup 
        mileageInput={mileageInput} 
        tyreWearRateAll={tyreWearRateAll} 
        text="Rear Right" 
        axlePosition="rearRight"  
        api={api} 
        url={url} 
        tyreComparisonRequestsID={tyreComparisonRequestsID}
        osfThread={osfThread}
        setOsfThread={setOsfThread}
        osfPremium={osfPremium}
        setOsfPremium={setOsfPremium}
        nsfThread={nsfThread}
        setNsfThread={setNsfThread}
        nsfPremium={nsfPremium}
        setNsfPremium={setNsfPremium}
        osrThread={osrThread}
        setOsrThread={setOsrThread}
        osrPremium={osrPremium}
        setOsrPremium={setOsrPremium}
        nsrThread={nsrThread}
        setNsrThread={setNsrThread}
        nsrPremium={nsrPremium}
        setNsrPremium={setNsrPremium}
        />
        </div>

        <div className="rearLeft">
        <Popup 
        mileageInput={mileageInput} 
        tyreWearRateAll={tyreWearRateAll} 
        text="Front Left"
        axlePosition="frontLeft"  
        api={api} 
        url={url} 
        tyreComparisonRequestsID={tyreComparisonRequestsID}
        osfThread={osfThread}
        setOsfThread={setOsfThread}
        osfPremium={osfPremium}
        setOsfPremium={setOsfPremium}
        nsfThread={nsfThread}
        setNsfThread={setNsfThread}
        nsfPremium={nsfPremium}
        setNsfPremium={setNsfPremium}
        osrThread={osrThread}
        setOsrThread={setOsrThread}
        osrPremium={osrPremium}
        setOsrPremium={setOsrPremium}
        nsrThread={nsrThread}
        setNsrThread={setNsrThread}
        nsrPremium={nsrPremium}
        setNsrPremium={setNsrPremium}
        />
        </div>

        <div className="rearRight">
        
        <Popup 
        mileageInput={mileageInput} 
        tyreWearRateAll={tyreWearRateAll} 
        text="Rear Left" 
        axlePosition="rearLeft"  
        api={api} 
        url={url} 
        tyreComparisonRequestsID={tyreComparisonRequestsID}
        osfThread={osfThread}
        setOsfThread={setOsfThread}
        osfPremium={osfPremium}
        setOsfPremium={setOsfPremium}
        nsfThread={nsfThread}
        setNsfThread={setNsfThread}
        nsfPremium={nsfPremium}
        setNsfPremium={setNsfPremium}
        osrThread={osrThread}
        setOsrThread={setOsrThread}
        osrPremium={osrPremium}
        setOsrPremium={setOsrPremium}
        nsrThread={nsrThread}
        setNsrThread={setNsrThread}
        nsrPremium={nsrPremium}
        setNsrPremium={setNsrPremium}
        />
        </div>

        <div className='drawnCar'>
            <img src={carDrawImg} alt="drawn image of a car" width={'300px'}/>
        </div>
        </div>
        </>
    )
}


 const Popup = ({
  mileageInput, 
  tyreWearRateAll, 
  text, 
  axlePosition, 
  api, 
  url, 
  tyreComparisonRequestsID,
  osfThread,
  setOsfThread,
  osfPremium,
  setOsfPremium,
  nsfThread,
  setNsfThread,
  nsfPremium,
  setNsfPremium,
  osrThread,
  setOsrThread,
  osrPremium,
  setOsrPremium,
  nsrThread,
  setNsrThread,
  nsrPremium,
  setNsrPremium,
}) => {
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [tyreHealth, setTyreHealth] = useState("8");
      const [tyreBrand, setTyreBrand] = useState("Budget");
      const [daysRemaining, setDaysRemaining] = useState(0);

      let actualWearRate = 0;

      if(axlePosition === 'frontLeft' || axlePosition === 'frontRight' ){
        
        if(tyreBrand === "Budget"){
          actualWearRate = tyreWearRateAll.frontBudget
          if(actualWearRate === 0){
            actualWearRate = tyreWearRateAll.rearBudget;
            if(actualWearRate === 0){
              actualWearRate = tyreWearRateAll.frontPremium * 0.75;
              if(actualWearRate === 0){
                actualWearRate = tyreWearRateAll.rearPremium * 0.75;
                if(actualWearRate === 0){
                  actualWearRate = tyreWearRateAll.frontTotal * 0.75;
                  if(actualWearRate === 0){
                    actualWearRate = tyreWearRateAll.rearTotal * 0.75;
                  }
                }
              }
            }
          }
          
        }else {
          actualWearRate = tyreWearRateAll.frontPremium
          if(actualWearRate === 0){
            actualWearRate = tyreWearRateAll.rearPremium
            if(actualWearRate === 0){
              actualWearRate = tyreWearRateAll.frontBudget / 0.75;
              if(actualWearRate === 0){
                actualWearRate = tyreWearRateAll.rearBudget / 0.75;
                if(actualWearRate === 0){
                  actualWearRate = tyreWearRateAll.frontTotal;
                  if(actualWearRate === 0){
                    actualWearRate = tyreWearRateAll.rearTotal;
                  }
                }
              }
            }
          }
        }
      }else {
        if(tyreBrand === "Budget"){
          actualWearRate = tyreWearRateAll.rearBudget
          if(actualWearRate === 0){
            actualWearRate = tyreWearRateAll.frontBudget
            if(actualWearRate === 0){
              actualWearRate = tyreWearRateAll.rearPremium * 0.75;
              if(actualWearRate === 0){
                actualWearRate = tyreWearRateAll.frontPremium * 0.75;
                if(actualWearRate === 0){
                  actualWearRate = tyreWearRateAll.rearTotal * 0.75;
                  if(actualWearRate === 0){
                    actualWearRate = tyreWearRateAll.frontTotal * 0.75;
                  }
                }
              }
            }  
          }
        }else {
          actualWearRate = tyreWearRateAll.rearPremium
          if(actualWearRate === 0){
            actualWearRate = tyreWearRateAll.frontPremium
            if(actualWearRate === 0){
              actualWearRate = tyreWearRateAll.rearBudget / 0.75;
              if(actualWearRate === 0){
                actualWearRate = tyreWearRateAll.frontBudget / 0.75;
                if(actualWearRate === 0){
                  actualWearRate = tyreWearRateAll.rearTotal;
                  if(actualWearRate === 0){
                    actualWearRate = tyreWearRateAll.frontTotal;
                  }
                }
              }
            }
          }
        }
      }

      

      const TyreData = (e) => {
        if(e.target.value !== "Premium" && e.target.value !== "Budget"){
          setTyreHealth(e.target.value);

          switch (axlePosition){
            case "frontLeft": setNsfThread(e.target.value); break;
            case "frontRight": setOsfThread(e.target.value); break;
            case "rearLeft": setNsrThread(e.target.value); break;
            case "rearRight": setOsrThread(e.target.value); break;
            default: break;
          }
          }else {
            setTyreBrand(e.target.value);
            if(e.target.value === "Premium"){
              switch(axlePosition){
                case "frontLeft": setNsfPremium(true); break;
                case "frontRight": setOsfPremium(true); break;
                case "rearLeft": setNsrPremium(true); break;
                case "rearRight": setOsrPremium(true); break;
                default: break;
              }

            }else {
              switch(axlePosition){
                case "frontLeft": setNsfPremium(false); break;
                case "frontRight": setOsfPremium(false); break;
                case "rearLeft": setNsrPremium(false); break;
                case "rearRight": setOsrPremium(false); break;
                default: break;
              }
            }
          }
      }

      useEffect(() => {
         let averageTyreLife = ((Number(tyreHealth) - 3) * (Number(actualWearRate) / 5)) / (mileageInput / 365) / 31;
         setDaysRemaining(Math.floor(averageTyreLife));
   },);

   useEffect(() => {
   if(tyreComparisonRequestsID !== "" && tyreComparisonRequestsID !== undefined){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${api}`);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(`${url}Vehicle/UpdateTyreComparisonRequestsResultData?RequestId=${tyreComparisonRequestsID}&osfTread=${osfThread}&osfPremium=${osfPremium}&nsfTread=${nsfThread}&nsfPremium=${nsfPremium}&osrTread=${osrThread}&osrPremium=${osrPremium}&nsrTread=${nsrThread}&nsrPremium=${nsrPremium}&wearRateFrontBudget=${tyreWearRateAll.frontBudget}&wearRateRearBudget=${tyreWearRateAll.rearBudget}&wearRateFrontPremium=${tyreWearRateAll.frontPremium}&wearRateRearPremium=${tyreWearRateAll.rearPremium}`, requestOptions)
      .then(response => response.text())
      .then(result => {
      })
      .catch(error => console.log('error', error));
   }
     
    
    
   }, [osfThread, osfPremium, nsfThread, nsfPremium, osrThread, osrPremium, nsrThread, nsrPremium])

  return (
    <>
    
    <Button variant="Light" style={{padding: "5px", marginBottom: "10px"}} onClick={handleShow}><Image src={EditIcon}  width="50%" /></Button>
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>{text} Axle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <Form.Select aria-label="Default select example" onChange={TyreData} value={tyreHealth}> 
                  
                  <DropdownOptions />

                </Form.Select>
                <br />

                <Form.Select aria-label="Default select example"  onChange={TyreData} value={tyreBrand}> 
                
                  <DropdownManufs />

                </Form.Select>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <p className='tyreInfoP' style={{borderBottom: Number(daysRemaining) > 6 ? '4px solid #008000' : Number(daysRemaining) > 3 && Number(daysRemaining) <= 6 ? '4px solid #FFA500' : '4px solid #FF0000'}}> {tyreBrand}, {tyreHealth}mm, {daysRemaining} Months Remaining</p>
    </>
  )
}