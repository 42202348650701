import Card from 'react-bootstrap/Card';
import { HandleClickWrongVehicle } from '../../Functions/HandleClickWrongVehicle/HandleClickWrongVehicle';

 const textCenterStyle = {
  backgroundColor: "#f8d038",
  margin: "0 250px 0 250px", 
  padding: "8px",
  fontWeight: "bold", fontFamily: "uknumberplateregular", 
  fontSize: "25px", 
  border: "2px solid black", 
  borderRadius: "5px",
  minWidth: "30%",
  minHeight: "20%",
}

export const CardHeaderTyres = (props) => {
    return (
        <>
            <Card border="secondary" style={{ width: '45rem', }}>
         <Card.Header className="text-center" style={textCenterStyle}>{props.registration}</Card.Header>
         <Card.Body>
           <Card.Title><b>{props.model}</b></Card.Title>
          
           {props.isDifferentTyres ? <Card.Text>
             <b>Most Popular Size:</b> {props.bestSizeFrontWidth} / {props.bestSizeFrontRatio} / {props.bestSizeFrontRim} / {props.bestSpeedFront}
           </Card.Text> 
           
           : 
           <>
             <Card.Text>
             <b>Most Popular Front Axle Size:</b> {props.bestSizeFrontWidth} / {props.bestSizeFrontRatio} / {props.bestSizeFrontRim} / {props.bestSpeedFront}
           </Card.Text>
           <Card.Text>
             <b>Most Popular Rear Axle Size:</b> {props.bestSizeRearWidth} / {props.bestSizeRearRatio} / {props.bestSizeRearRim} / {props.bestSpeedRear}
           </Card.Text>
           <Card.Text>
             <b>NOTE:</b> This vehicle is likely to have different size front and rear tyres !
           </Card.Text>
           </> }
           {/* <span type="button" className='differentVehicle' onClick={props.handleClickWrongVehicle}>Not your vehicle ?</span> */}
           <span type="button" className='differentVehicle' onClick={() => HandleClickWrongVehicle(props.handleWrongVehicleValues)}>Not your vehicle ?</span>
           
         </Card.Body>
       </Card>
        </>
    )
}