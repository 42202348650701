
// import { CDBFooter, CDBBox, CDBBtn, CDBIcon } from 'cdbreact';
// import companyLogo from '../../Pictures/driverhelpline.png';
// import './Footer.css'

// function Footer () {
//     return (
       
    //   <CDBFooter className="shadow">
    //   <CDBBox
    //     display="flex"
    //     justifyContent="between"
    //     alignItems="center"
    //     className="mx-auto py-4 flex-wrap"
    //     style={{ width: '80%' }}
    //   >
    //     <CDBBox display="flex" alignItems="center">
        
    //     <small className="ml-2"> Powered by </small>

    //       <a href="/" className="d-flex align-items-center p-0 text-dark">
    //         <img
    //           alt="logo"
    //           src={companyLogo}
    //           width="120px"
    //         />
    //       </a>
    //       <small className="ml-2">&copy;2022.</small>
    //     </CDBBox>
    //     <CDBBox display="flex">
    //     </CDBBox>
    //   </CDBBox>
    // </CDBFooter>

//     <CDBFooter className="shadow">
// <CDBBox
// display="flex"
// justifyContent="between"
// alignItems="center"
// className="mx-auto py-4 flex-wrap"
// style={{ width: '80%' }}
// >
// <CDBBox display="flex" alignItems="center">
// <small className="ml-2">Powered by</small>
// <a href="/" className="d-flex align-items-center p-0 text-dark">
// <img
//                          alt="logo"
//                          src={companyLogo}
//                          width="120px"
//                      />
// </a>
// <small className="ml-2">©2022.</small>
// </CDBBox>
// <CDBBox display="flex">
// </CDBBox>
// </CDBBox>
// <CDBBox
// display="flex"
// justifyContent="center"
// alignItems="center"
// className="mx-auto py-2"
// style={{ width: '80%' }}
// >
// <small className="text-muted">
// Legal Disclaimer: The contents of this website are general tyre recommendations for information only based on the average use of a vehicle (passenger car and retail van), Kwik-Fit’s experience and internal fleet data. The high mileage calculator does not take into account the following factors, including (but not limited to): driving habits, vehicle condition, road conditions, vehicle load or whether a vehicle has been regularly maintained. Kwik-Fit accepts no responsibility for any information contained within this website and disclaims and excludes any liability in respect of the contents or for action taken based on this information. The contents of this website may be downloaded for personal use only. You may not reproduce, copy, distribute or publish – whether electronically or in hard copy – any information contained within this website without the prior written consent of Kwik-Fit (GB) Limited.
// </small>
// </CDBBox>
// </CDBFooter>
//     )

// }

// export default Footer;



import { CDBFooter, CDBBox } from 'cdbreact';
import companyLogo from '../../Pictures/driverhelpline.png';
import './Footer.css';

function Footer() {
  return (
    <CDBFooter className="bg-light text-center text-lg-start">
      
      <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        <h6>Legal Disclaimer</h6>
        <small>
        The contents of this website are general tyre recommendations for information only based on the average use of a vehicle (passenger car and retail van), Kwik-Fit's experience and internal fleet data.
        </small>
        <br />
        <small>
        The high mileage calculator does not take into account the following factors, including (but not limited to): driving habits, vehicle condition, road conditions, vehicle load or whether a vehicle has been regularly maintained.
        </small>
        <br />
        <small>
        Kwik-Fit accepts no responsibility for any information contained within this website and disclaims and excludes any liability in respect of the contents or for action taken based on this information.
        </small>
        <br />
        <small>
        The contents of this website may be downloaded for personal use only. You may not reproduce, copy, distribute or publish - whether electronically or in hard copy - any information contained within this website without the prior written consent of Kwik-Fit (GB) Limited
        </small>
      </div>
      <div className="container p-4">
        <CDBBox display="flex" alignItems="center" justifyContent="between">
          <div className="d-flex align-items-center">
            <small className="ms-2">Powered by  </small>
            <img src={companyLogo} style={{margin: '10px'}} alt="logo" width="120" />
          </div>
          <small>&copy; 2023 driverhelpline ltd. All rights reserved.</small>
        </CDBBox>
      </div>
    </CDBFooter>
  );
}

export default Footer;
