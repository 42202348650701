import { useState, useEffect } from "react";

const AllDropdowns = () => {
    const [apiKey, setApiKey] = useState(window.localStorage.getItem("API_KEY"));
    const ratioUrl = `https://dataengine.paymonthlytyres.com/api/Vehicle/Tyres/GetRatioOptions?width=`;
    const rimUrl = `https://dataengine.paymonthlytyres.com/api/Vehicle/Tyres/GetRimOptions?width=`;
    const speedUrl = `https://dataengine.paymonthlytyres.com/api/Vehicle/Tyres/GetSpeedOptions?width=`;

    const [widthVal, setWidthVal] = useState("");
    const [ratio, setRatio] = useState([]);
    const [ratioHTMLElement, setRatioHTMLElement] = useState([])
    const [dropdownWidthValue, setDropdownWidthValue] = useState("");

    const [ratioVal, setRatioVal] = useState("");
    const [dropdownRatioValue, setDropdownRatioValue] = useState("");

    const [rim, setRim] = useState([]);
    const [rimHTMLElement, setRimHTMLElement] = useState([])

    const [speed, setSpeed] = useState([]);
    const [speedHTMLElement, setspeedHTMLElement] = useState([])

    const [rimVal, setRimVal] = useState("");
    const [speedVal, setSpeedVal] = useState("");
    const [dropdownRimValue, setDropdownRimValue] = useState("");
    const [dropdownSpeedValue, setDropdownSpeedValue] = useState("");

    
// setWVal needs out 
   const RatioDropdownFunc = () => {
    

    function getRatioValues(e){
        setRatio([])
        setWidthVal("");     
        setWidthVal(e.target.value);
        setDropdownWidthValue(e.target.value);
        
        var myHeaders = new Headers();
          myHeaders.append("Authorization", `Basic ${apiKey}`);
    
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
    
          fetch(`${ratioUrl}${e.target.value}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              
                Object.values(result).forEach(line => {
                  setRatio(oldState => [...oldState, line])
                })
            })
    
        .catch(error => console.log('error', error));
      };

      useEffect(() => {
    
        const ratioInfo = ratio.map((ele, index) => {
          return (
              <option key={`ratio-${index}`} value={ele["Value"]}>{ele["Value"]}</option>
          )
        })
        setRatioHTMLElement(ratioInfo)
    },[ratio]);
    

      return {
        widthVal,
        setWidthVal,
        dropdownWidthValue,
        setDropdownWidthValue,
        ratioHTMLElement,
        getRatioValues,
        
      }
}

 const RimDropdownFunc = () => {

    

    function getRimValues(e){
        setRim([]);
        setRatioVal(e.target.value)
        setDropdownRatioValue(e.target.value)
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic ${apiKey}`);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        fetch(`${rimUrl}${widthVal}&ratio=${e.target.value}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            
            Object.values(result).forEach(line => {
              setRim(oldState => [...oldState, line])
            })
          })
          .catch(error => console.log('error', error));
      };


      useEffect(() => {
    
        const rimInfo = rim.map((ele, index) => {
          return (
              <option key={`rim-${index}`} value={ele["Value"]}>{ele["Value"]}</option>
          )
        })
        setRimHTMLElement(rimInfo)
      },[rim]);

      return {
        ratioVal,
        setRatioVal,
        dropdownRatioValue,
        setDropdownRatioValue,
        rimHTMLElement,
        getRimValues,
      }
}

 const SpeedDropdownFunc = () => {


  function getSpeedValues(e){
    setSpeed([]);
    setRimVal(e.target.value);
    setDropdownRimValue(e.target.value);

    var myHeaders = new Headers();
      myHeaders.append("Authorization", `Basic ${apiKey}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${speedUrl}${widthVal}&ratio=${ratioVal}&rim=${e.target.value}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          Object.values(result).forEach(line => {
            setSpeed(oldState => [...oldState, line])
          })
        })
        .catch(error => console.log('error', error));

  }

  function controlledSpeedVal(e){
    setSpeedVal(e.target.value);
    setDropdownSpeedValue(e.target.value);
  }

  useEffect(() => {
    
    const speedInfo = speed.map((ele, index) => {
      return (
          <option key={`speed-${index}`} value={ele["Value"]}>{ele["Value"]}</option>
      )
    })
    setspeedHTMLElement(speedInfo)
  },[speed]);

  return {
    speedHTMLElement,
    rimVal,
    setRimVal,
    speedVal,
    setSpeedVal,
    dropdownRimValue,
    setDropdownRimValue,
    dropdownSpeedValue,
    setDropdownSpeedValue,
    getSpeedValues,
    controlledSpeedVal,
  }
 }


 return {
    RatioDropdownFunc,
    RimDropdownFunc,
    SpeedDropdownFunc,
}
}

    


export default AllDropdowns