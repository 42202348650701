export const HandleClick = (props) => {
    
    let [clearData, setIsLoading, apiKey, baseUrl, message, setModel, setRegistration, setFleetEdi, setBestSizeFrontWidth, setBestSizeFrontRatio, setBestSpeedFront, setIsDifferentTyres, setBestSizeFrontRim, setBestSizeRearWidth, setBestSizeRearRatio, setBestSizeRearRim, setBestSpeedRear, setTyreData, navigate, setInvalidPlate, setNoVehicleData, setTyresAreDifferent] = props;
       
          clearData();
          setIsLoading(true);
          
          // We need to secure these authentication details 
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Basic ${apiKey}`);
         
      
          var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
          };
      
          fetch(`${baseUrl}${message}&useExisting=true`, requestOptions)
              .then(response => response.json())
              .then(data => {
                let tyreSizeUnpacked;
                let bestSizeFront;
                let bestSizeBack;

                // Unpacking data coming as an object
                if(data["TyreSizeOptions"].length < 1){
                  // setNoVehicleData(true);
                  // setTimeout(() => {
                  //   setNoVehicleData(false)
                  // }, 3000)
                  // setIsLoading(false);
                
                 
                  tyreSizeUnpacked = [{"ID": "--", "Width": "--", "Ratio": "--", "Rim": "--", "Speed": "--", "TyrePrice": 0, "loadRating": ""}];
                  bestSizeFront = {"ID": 1, "Width": 'No Data', "Ratio": "No Data", "Rim": "No Data", "Speed": "No Data", "TyrePrice": 0, "loadRating": ""};
                  bestSizeBack = {"ID": 2, "Width": 'No Data', "Ratio": "No Data", "Rim": "No Data", "Speed": "No Data", "TyrePrice": 0, "loadRating": ""};
                }else {

                  tyreSizeUnpacked = data["TyreSizeOptions"];
                   bestSizeFront = data["TyreSizeStandardFront"]
                   bestSizeBack = data["TyreSizeStandardRear"]
                }
                const fleetNetEdi = data["FleetNetEDI"];
                  // Checking if we have valid reg
                if(fleetNetEdi !== 0){
                  // Setting Card information variables
                  setModel(data["Model"]);
                  setRegistration(data["Registration"]);
                  setFleetEdi(fleetNetEdi);
      
                  if(data["TyreSizeDifferent"] === false){
                    
                    // Setting information about most used size using for all wheels
                    setBestSizeFrontWidth(bestSizeFront["Width"]);
                    setBestSizeFrontRatio(bestSizeFront["Ratio"]);
                    setBestSizeFrontRim(bestSizeFront["Rim"]);
                    setBestSpeedFront(bestSizeFront["Speed"]);
                    setIsDifferentTyres(true);
                    setTyresAreDifferent(false);
                    
                  }else{
                    
                    // Setting information about most used size for front wheels
                    setBestSizeFrontWidth(bestSizeFront["Width"]);
                    setBestSizeFrontRatio(bestSizeFront["Ratio"]);
                    setBestSizeFrontRim(bestSizeFront["Rim"]);
                    setBestSpeedFront(bestSizeFront["Speed"]);
                    // Setting information about most used size for rear wheels 
                    setBestSizeRearWidth(bestSizeBack["Width"]);
                    setBestSizeRearRatio(bestSizeBack["Ratio"]);
                    setBestSizeRearRim(bestSizeBack["Rim"]);
                    setBestSpeedRear(bestSizeBack["Speed"]);
      
                    setIsDifferentTyres(false);
                    setTyresAreDifferent(true);
      
                  }
                  
                  // Populating the options for dropdown menu 
                  tyreSizeUnpacked.forEach(line => {
                    setTyreData(oldState => [...oldState, line])
                  })
                 
                  navigate("/tyre-data");
                }
                else{
                  setInvalidPlate(true);
                  setTimeout(() => {
                    setInvalidPlate(false)
                  }, 3000)
                }
                setIsLoading(false);

                
              })
              .catch(error => console.log(`TESTING ERROR: ${error}`))  
  };