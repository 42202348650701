
import { Outlet, Navigate } from "react-router-dom";    
import { useContext } from "react";
import { LoginContext } from "../Context/LoginContext";

const PrivateRoutes = () => {
    const { userIsLoggedIn } = useContext(LoginContext);

    let auth = {"token": userIsLoggedIn}
    return (
        auth.token ? <Outlet /> : <Navigate to="/" />
    )

}


export default PrivateRoutes