
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import CardGroup from 'react-bootstrap/CardGroup';
import './Charts.css';
import { MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import { CarDraw } from './CarDraw/CarDraw';
// import Image from 'react-bootstrap/Image'
// import KwikFitLogo from '../Pictures/kwikfitclub_logo.png';
import { TableHead } from './Table/TableHead';
import { CardHeaderCharts } from './CardHeaderCharts/CardHeaderCharts';
import { DisplayChart, DisplayChartRear } from './DisplayChart/DisplayChart';
import { useState, useEffect } from 'react';
import CompanyLogo from '../Components/Image';


function Charts(props) {

    // const [bestTableRow, setBestTableRow] = useState("");
    // const [bestTableRowSecond, setBestTableRowSecond] = useState("");
    const [arrayTyres, setArrayTyres] = useState(["Manufacturer"]);
    const [arrayWearRate, setArrayWearRate] = useState([""]);
    const [colorHandlerOne, setColorHandlerOne] = useState(false);
    const [colorHandlerTwo, setColorHandlerTwo] = useState(false);
    const [colorHandlerThree, setColorHandlerThree] = useState(false);

    const [handledTableRowsArray, setHandleTableRowsArray] = useState([]);
    const [totalFitments, setTotalFitments] = useState(0);
   


  
    useEffect(() => {

      setHandleTableRowsArray(props.tableRowsArray.length > 0 ? props.tableRowsArray : [['No data', '0', '0', '0', '0']]);
      

            let averageFrontFitments = 0;
            let averageRearFitments = 0;
            props.tableRowsArray.forEach((ele, i) => {
              let frontFitments = Number(ele[6]);
              let rearFitments = Number(ele[7]);
              averageFrontFitments += frontFitments;
              averageRearFitments += rearFitments;
      });
  
      setTotalFitments(averageFrontFitments + averageRearFitments);

    },[props.tableRowsArray])
   

   
    useEffect(() => {
      props.chartsArray.forEach((row, i) => {
        if(i % 2 === 0){
          setArrayTyres((oldState) => [...oldState, row]);
        }else {
          setArrayWearRate((oldState) => [...oldState, row])
        }
        
        if(i === 0 && row === "Budget"){
          setColorHandlerOne(true)
        }else if(i === 2 && row === "Budget"){
          setColorHandlerTwo(true)
        }else if(i === 4 && row === "Budget"){
          setColorHandlerThree(true)
        }
    })
    },[props.chartsArray])
    

    const HandleMileageValue = (e) => {
      props.setMileageInput(e.target.value)
    }

    const handleSubmitMileage = () => {
      props.setStoredMileage(props.mileageInput);
      }

     
    const handleTyreChange = () => {
      // props.setChartsArray([]);
      props.navigate("/tyre-data");
    }


    useEffect(() => {
      
      if(props.tyreComparisonRequestsID !== "" && props.tyreComparisonRequestsID !== undefined){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic ${props.apiKey}`);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };

    fetch(`${props.url}Vehicle/UpdateAnnualMiles?AnnualMiles=${props.storedMileage}&RequestId=${props.tyreComparisonRequestsID}`, requestOptions)
      .then(response => response.text())
      .then(result => {
      })
      .catch(error => console.log('error', error));
      }
    },[props.tyreComparisonRequestsID, props.storedMileage])

    useEffect(() => {
      if (!props.registration) {
        props.navigate('/dashboard-page');
      }
    }, [props.registration, props.navigate]);

    return (
      <>
      {/* {props.registration
      ? */}
        <>
          {/* {((handledTableRowsArray[0] !== "No data"|| handledTableRowsArrayTwo[0] !== "No data") )  */}
          {(handledTableRowsArray[0] !== "No data"  && totalFitments >= 25)  

      ?
      <div className='chartsDiv' id='topOfPage'>
      <div className='chartCardDiv'>
      
      <CardHeaderCharts 
          model={props.model}
          registration={props.registration}
          dropdownWidthValue={props.dropdownWidthValue}
          dropdownRatioValue={props.dropdownRatioValue}
          dropdownRimValue={props.dropdownRimValue}
          dropdownSpeedValue={props.dropdownSpeedValue}
          dropdownWidthValueSecond={props.dropdownWidthValueSecond}
          dropdownRatioValueSecond={props.dropdownRatioValueSecond}
          dropdownRimValueSecond={props.dropdownRimValueSecond}
          dropdownSpeedValueSecond={props.dropdownSpeedValueSecond}
          isFrontTyre={props.isFrontTyre}
          tyresAreDifferent={props.tyresAreDifferent}
        /> 
       </div>

       <Button variant="secondary" style={{marginRight: "10px"}} onClick={props.newQueryRequest}>Change Vehicle</Button>
      
      {<Button variant="secondary" style={{marginRight: "10px"}} onClick={handleTyreChange} >Change Tyre Details</Button>}
       <div className='chartsTable'>
         <h3 className='headerDivCharts'>Suggested Fitment</h3>
            <div className='bestMatch'>
              <div className='eleOne'>
              
              {handledTableRowsArray[0] === "No data" ? (
                <Card border="light" style={{ width: "18rem" }}>
                  {/* <Card.Header>Changing Tyres?</Card.Header> */}
                  <Card.Body>
                    <Card.Title>We are sorry.</Card.Title>
                    <Card.Text>No data available for this size.</Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                <>
                  <h5>
                    Our data would recommend {props.suggestedFitments.tyreBrand !== 'Budget'&& props.suggestedFitments.tyreBrand !=='Average' ? <CompanyLogo imageSource={props.suggestedFitments.tyreBrand}/> : props.suggestedFitments.tyreBrand === 'Budget' ? <b>Budget</b> : "Average"} based on{" "}
                    <b>{totalFitments}</b> tyre fitments.{" "}
                  </h5>
                  <CardGroup>
                    <Card
                      border="light"
                      style={{ width: "18rem", margin: "20px" }}
                    >
                      {/* <Card.Header>Changing Tyres?</Card.Header> */}
                      <Card.Body>
                        {/* <Card.Title><b>{row.props.children[2].props.children}</b></Card.Title> */}
                        <Card.Title>
                          <b>{props.suggestedFitments.wearRate}%</b>
                        </Card.Title>
                        <Card.Text>more tyre life than average for this tyre size and vehicle type</Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      border="light"
                      style={{ width: "18rem", margin: "20px" }}
                    >
                      {/* <Card.Header>Changing Tyres?</Card.Header> */}
                      <Card.Body>
                        <Card.Title>
                          <b>£{(props.suggestedFitments.totalSavings).toFixed(2)}</b>
                        </Card.Title>
                        <Card.Text>
                          Saving compared to average over the life of a tyre
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      border="light"
                      style={{ width: "18rem", margin: "20px" }}
                    >
                      {/* <Card.Header>Changing Tyres?</Card.Header> */}
                      <Card.Body>
                        <Card.Title>
                          <b>£{(props.suggestedFitments.totalSavings * 4).toFixed(2)}</b>
                        </Card.Title>
                        <Card.Text>
                          Potential total savings for the life of all your tyres
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </CardGroup>
                </>
              )}
            
              </div>
            <br/>
            {/* <div className='eleTwo'>
              {bestTableRowSecond}
            </div> */}
            </div>
            <DisplayChart 
              arrayTyres={arrayTyres}
              arrayWearRate={arrayWearRate}
              colorHandlerOne={colorHandlerOne}
              colorHandlerTwo={colorHandlerTwo}
              colorHandlerThree={colorHandlerThree}
      /> 
       </div>

        <div>
        <h3 className='headerDivCharts'>Enter your average annual mileage to improve analysis</h3>
   </div>
        <MDBInput label='Annual Mileage' id='typeNumber' type='number' min="0" onChange={HandleMileageValue} value={props.mileageInput}/>
        <br />
        <MDBBtn className='me-1' type='submit' onClick={handleSubmitMileage}>Re-Calculate</MDBBtn>
          <> 
          {handledTableRowsArray[0] !== "No data"
           &&       
            <>
            <div>
          <h3 className='headerDivCharts'>Tyre Analysis</h3>
              {/* <MDBInput label='Comparison Tyre Price' id='typeNumber' type='number' min="0" onChange={(e) => props.setAverageTyrePrice(e.target.value)} value={(props.averageTyrePrice)}/> */}
              {/* <Card>
                  <Card.Body>Data based on <b>{totalFitments} </b> tyre fitments </Card.Body>
                  
             </Card> */}
                  <div className='averageTyrePrice'>
                  </div>
                  <Table striped className='chartsTable'>
                    
                  <TableHead />
                  <tbody>
                    {props.createTableRows}
                   
                  </tbody>
              </Table>   
              </div>
            </>
          }   
<div>
        <h3 className='headerDivCharts'>How long is left on my tyres?</h3>
    <CarDraw 
      mileageInput={props.mileageInput}
      tyreWearRateAll={props.tyreWearRateAll}
      api={props.apiKey}
      url={props.url}
      tyreComparisonRequestsID={props.tyreComparisonRequestsID}
      />
   </div>

      {/* <div>
      <h3 className='headerDivCharts'>Would it be worth joining Kwik Fit Club ?</h3>

        <Image src={KwikFitLogo} fluid width="14%" id='imageBanner'  />
        <MDBBtn className='me-1' type='submit' >GET CLUB QUOTE</MDBBtn>
        </div> */}
            <Card>
                  <Card.Body><b>Credibility explained:</b></Card.Body>
                  
                  <Card.Body><span style={{color: '#008000'}}>High</span>: Over 25 tyre fitments <br/><span style={{color: "#FFA500"}}>Medium</span>: Under 25 and over 9 tyre fitments <br/><span style={{color: "#ff0000"}}>Low</span>: Under 10 tyre fitments</Card.Body>
                 
             </Card>
</>
</div>  
:
    <div className='crashedPageDiv'>
       <div className='chartCardDiv'>
      
      <CardHeaderCharts 
          model={props.model}
          registration={props.registration}
          dropdownWidthValue={props.dropdownWidthValue}
          dropdownRatioValue={props.dropdownRatioValue}
          dropdownRimValue={props.dropdownRimValue}
          dropdownSpeedValue={props.dropdownSpeedValue}
          dropdownWidthValueSecond={props.dropdownWidthValueSecond}
          dropdownRatioValueSecond={props.dropdownRatioValueSecond}
          dropdownRimValueSecond={props.dropdownRimValueSecond}
          dropdownSpeedValueSecond={props.dropdownSpeedValueSecond}
          isFrontTyre={props.isFrontTyre}
          tyresAreDifferent={props.tyresAreDifferent}
        /> 
       </div>
      <div className='crashedPage'><h2>Sorry we don't have enough data available for these tyres on this vehicle at the moment </h2> </div>
      <div className="d-grid gap-2"><Button variant="primary" size='sm' type='button' onClick={props.newQueryRequest}> Choose New Reg </Button></div>
      
    </div> 
    }
        </>
      {/* :
      props.navigate("/dashboard-page")
      } */}
</>  
    )
}


export default Charts