import Form from 'react-bootstrap/Form';
import { MDBBtn } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react'
import './TyreData.css'
import Spinner from 'react-bootstrap/Spinner';
import "../Fonts/stylesheet.css";
import { CardHeaderTyres } from './Card/CardHeaderTyres';
import { ManualInputDropdown, ManualInputDropdownTwo } from './Dropdown/ManualInputDropdown';
import { HandleData } from '../Functions/HandleData/HandleData';

function TyreData (props) {
  
    const [btnDisabled, setBtnDisabled] = useState(false);


    useEffect(() => {
      if(props.tyresAreDifferent === false){

        if(props.presetVal === "" || props.presetVal === "Tyre Size" ){
          setBtnDisabled(true);
        }else{
          if(props.presetVal === "manualInput"){
            if(props.widthVal !== "" && props.widthVal  !=="Width" && props.ratioVal !=="" && props.ratioVal !== "Ratio" && props.rimVal !== "" && props.rimVal !== "Rim" && props.speedVal !== "" && props.speedVal !== "Speed"){
               setBtnDisabled(false);
  
            }else{
               setBtnDisabled(true);
            }
          }else{
            setBtnDisabled(false);
  
          }
        }
      }else {
        if(props.presetVal === "" || props.presetVal === "Tyre Size" || props.presetValTwo === "" || props.presetValTwo === "Tyre Size Back"){
          setBtnDisabled(true);
        }else{
          if(props.presetVal === "manualInput"){
            if(props.widthVal  !== "" && props.widthVal  !=="Width" && props.ratioVal !=="" && props.ratioVal !== "Ratio" && props.rimVal !== "" && props.rimVal !== "Rim" && props.speedVal !== "" && props.speedVal !== "Speed"){
               setBtnDisabled(false);
  
            }else{
               setBtnDisabled(true);
            }
          }else{
            setBtnDisabled(false);
  
          }
        }
      }

      
    },)

    useEffect(() => {
      props.setPresetVal([props.bestSizeFrontWidth, props.bestSizeFrontRatio, props.bestSizeFrontRim, props.bestSpeedFront])
      props.handleDefaultSelectOption([props.bestSizeFrontWidth, props.bestSizeFrontRatio, props.bestSizeFrontRim, props.bestSpeedFront])
      props.setPresetValTwo([props.bestSizeRearWidth, props.bestSizeRearRatio, props.bestSizeRearRim, props.bestSpeedRear])
      props.handleDefaultSelectOptionTwo([props.bestSizeRearWidth, props.bestSizeRearRatio, props.bestSizeRearRim, props.bestSpeedRear])
    }, [btnDisabled])
    
    useEffect(() => {
      if (props.registration === '') {
        props.navigate('/dashboard-page');
      }
    }, [props.registration, props.navigate]);
    
    return (
        <>
        
        {props.isLoading 
        ?
        <div>
        <div className='loadingPage'><Spinner animation="grow" /></div>
    </div> 
    :
    <div className="tyreDataMainDiv">
         {/* {props.registration !== "" 
         ? */}
         <>
         <CardHeaderTyres
          model={props.model}
          registration={props.registration}
          bestSizeFrontWidth={props.bestSizeFrontWidth}
          bestSizeFrontRatio={props.bestSizeFrontRatio}
          bestSizeFrontRim={props.bestSizeFrontRim}
          bestSizeRearWidth={props.bestSizeRearWidth}
          bestSizeRearRatio={props.bestSizeRearRatio}
          bestSizeRearRim={props.bestSizeRearRim}
          isDifferentTyres={props.isDifferentTyres}
          bestSpeedFront={props.bestSpeedFront}
          bestSpeedRear={props.bestSpeedRear}
          // handleClickWrongVehicle={props.handleClickWrongVehicle}
          handleWrongVehicleValues={props.handleWrongVehicleValues}
         />
         

       <br />
       <div>
            <div className='tyreSizeForms'>
              <Form.Label><b>Choose Front Tyre Size</b></Form.Label>
                <Form.Select aria-label="Default select example" defaultValue={props.presetVal} onChange={props.handleSelectOption}>
                <option value="Tyre Size">Tyre Size</option>
                {props.unpackedTyreData}
                <option value="manualInput">Not Listed - Enter Manually</option>

            </Form.Select>
          
            {props.isTrue &&  <div className="manualInputDiv">
             <ManualInputDropdown 
            widthVal={props.widthVal}
            getRatioValues={props.getRatioValues}
            widthHTMLElement={props.widthHTMLElement}
            ratioVal={props.ratioVal}
            getRimValues={props.getRimValues}
            ratioHTMLElement={props.ratioHTMLElement}
            rimVal={props.rimVal}
            getSpeedValues={props.getSpeedValues}
            rimHTMLElement={props.rimHTMLElement}
            speedVal={props.speedVal}
            controlledSpeedVal={props.controlledSpeedVal}
            speedHTMLElement={props.speedHTMLElement}
            />
        </div>}
            <br />
            <div>
            <Form>
              <Form.Check 
                type="switch"
                id="custom-switch"
                label="Front And Rear Tyres Are The Same Size"
                checked={!props.tyresAreDifferent}
                onChange={() => props.setTyresAreDifferent(!props.tyresAreDifferent)}
              />
              </Form>
            </div>
            
            {props.tyresAreDifferent && 
              <>
                <Form.Label><b>Choose Rear Tyre Size</b></Form.Label>
               
                <Form.Select aria-label="Default select example" value={props.presetValTwo} onChange={props.handleSelectOptionTwo}>
                <option value="Tyre Size Back">Tyre Size</option>
                {props.unpackedTyreData}
                <option value="manualInputTwo">Not Listed - Enter Manually</option>

            </Form.Select>
             {/* Manual input dropdowns  */}
             {props.isTrueSecond &&  <div className="manualInputDiv">
                <ManualInputDropdownTwo 
                widthValSecond={props.widthValSecond}
                getRatioValuesSecond={props.getRatioValuesSecond}
                widthHTMLElementSecond={props.widthHTMLElementSecond}
                ratioValSecond={props.ratioValSecond}
                getRimValuesSecond={props.getRimValuesSecond}
                ratioHTMLElementSecond={props.ratioHTMLElementSecond}
                rimValSecond={props.rimValSecond}
                getSpeedValuesSecond={props.getSpeedValuesSecond}
                rimHTMLElementSecond={props.rimHTMLElementSecond}
                speedValSecond={props.speedValSecond}
                controlledSpeedValSecond={props.controlledSpeedValSecond}
                speedHTMLElementSecond={props.speedHTMLElementSecond}
                />
            </div>}
              </>
            }
            </div> 
      </div>
           </>
       {/* : */}
       {/* props.navigate("/dashboard-page")
        } */}
        
      <br/>
        <Button variant="secondary" onClick={() => props.navigate("/dashboard-page")}>Change Reg</Button>
        {/* <MDBBtn className='me-1' onClick={props.handleData} disabled={btnDisabled}> */}
        <MDBBtn className='me-1' id="tyreDataProceed" onClick={() => HandleData(props.handleDataValues)} disabled={btnDisabled}>
        Proceed
      </MDBBtn>
       </div>
      }
       </>
    )
}

export default TyreData;